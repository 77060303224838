
import NavBar from "../NavBar/NavBar"
import WebsiteControl from "../WebsitesControl/WebsiteControl"
import Creatives from "../Creatives/Creatives"
import Automation from "../Automation/Automation"
import Logs from '../Logs/Logs'
import SubscribersData from '../Subscribers/SubscribersData'

import { useParams } from 'react-router-dom'
import { Navigate } from "react-router-dom"

import useWindowSize from '../../assets/hooks/useWindowSize.js'

const Main = () => {
    const params = useParams()
    const page = params.page
    let {width} = useWindowSize()
    const validRoutes = ['creatives', 'automation', 'websites','logs', 'subscribers']

    return (
        <>
            {width > 500 && <NavBar/>}
            {(page === 'websites'  && <WebsiteControl />)}
            {(page === 'creatives' && <Creatives />)}
            {(page === 'automation' && <Automation />)}
            {(page === 'logs' && <Logs />)}
            {(page === 'subscribers' && <SubscribersData />)}
            
            {!validRoutes.includes(page) && <Navigate to='/'/>}
        </>
    )
}

export default Main