import classes from './Creatives.module.scss';


const CreativesTableItemClicks = (props) => {
    const data = props.data
    const clicks_data = props.data.clicks_data
    const showByQueryDiv = props.showByQueryDiv

    
    return (
        <tr>
            {showByQueryDiv?(
                <>
                    <td>{data.domain}</td>
                    <td> <a href={data.favicon} rel="noreferrer" target='_blank'> <img alt='' className={classes.favicon_icon} src={data.favicon}/> </a> </td>
                    <td>{data.title}</td>
                    <td>{data.image}</td>
                    <td>{data.link}</td>
                    <td>{clicks_data}</td>
                </>
            ):(
                <>
                    <td>{data.domain}</td>
                    <td> <a href={data.favicon} rel="noreferrer" target='_blank'> <img alt='' className={classes.favicon_icon} src={data.favicon}/> </a></td>
                    <td>{data.title}</td>
                    <td>{data.image}</td>
                    <td>{data.link}</td>
                
                    <td>{clicks_data.today}</td>
                    <td>{clicks_data.yesterday}</td>
                    <td>{clicks_data.last_seven_days}</td>
                    <td>{clicks_data.last_thirty_days}</td>
                    <td>{clicks_data.this_month}</td>
                    <td>{clicks_data.last_month}</td>
                    <td>{clicks_data.total_clicks}</td>
                    <td>{clicks_data.updated}</td>
                    <td>{clicks_data.u_id}</td>
                </>
            )}
        </tr>
    )
}

export default CreativesTableItemClicks;
