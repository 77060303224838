
const HeadersAllWebsites = (props) => {
    let headers_names = props.headernames

    return (
        <tr>
            <th><input id='selectAll' type="checkbox" name='selectAll' checked={props.isCheckAll} onChange={props.handleSelectAll}/><p>Select All</p></th>
            <th>{headers_names.w_id}</th>
            <th>{headers_names.favicon}</th>
            <th>{headers_names.domain}</th>
            <th>{headers_names.verified}</th>
            <th>{headers_names.verificationChecked}</th>
            <th>{headers_names.verify_now}</th>
            <th>{headers_names.total_subscribers}</th>
            <th>{headers_names.total_unsubscribed}</th>
            <th>{headers_names.total_articles}</th>
            <th>{headers_names.creatives_created}</th>
            <th>{headers_names.bulk_schedule_now}</th>
            <th>{headers_names.dispatched}</th>
            <th>{headers_names.dispatch_failed}</th>
            <th>{headers_names.impressions}</th>
            <th>{headers_names.clicks}</th>
            <th>{headers_names.ctr}</th>
            <th>{headers_names.actions_tab}</th>
    </tr>
    )
}
export default HeadersAllWebsites