import classes from './Creatives.module.scss';
import pencil from '../../assets/icons/pencil.svg'
import delete_red from '../../assets/icons/delete_red.svg'
import send_now from '../../assets/icons/send.svg'
import globe_gray from '../../assets/icons/globe_gray.svg'


const CreativesTableItem = (props) => {
    const data = props.data
    const index = props.index
    const isCheckList = props.isCheckList

    return (
        <tr>
            <td><input id={index} onChange={props.handleClick} type="checkbox" name={data.domain} checked={isCheckList.includes(index)}/></td>
            <td><a href={data.favicon} rel="noreferrer" target='_blank'> <img className={classes.favicon_icon} src={data.favicon} alt='favicon'/></a></td>

            <td>{data.domain}</td>
            <td>{data.post_id}</td>
            <td>{data.title}</td>
            <td>{data.description}...</td>
            <td className={classes.edit_image_td}>
                <div>
                    {data.image !== "" || data.image != "error"?(<a href={data.image} rel="noreferrer" target='_blank'><img className={classes.creative_image_section} src={data.image} alt={''} target='_blank'/></a>):(<></>)}
                    <img id={index} className={classes.action_button_each} src={pencil} alt='Edit' onClick={props.toggleEditImage}/>
                </div>
            </td> 

            <td><a href={data.link} rel="noreferrer" target='_blank'>{data.link}</a></td>
            <td>{data.target_websites}</td>
            <td>{data.expected_subs}</td>

            <td>{data.run_daily ? 'True': 'False'}</td>
            <td>{data.automate ? 'True': 'False'}</td>
            <td>{data.already_ran_today ? 'True': 'False'}</td>
            <td>{data.run_daily ? '': data.scheduled_date}</td>

            <td>{data.schedule_on}</td>
            <td>{data.status}</td>
            <td>{data.scheduled ? 'True': 'False'}</td>
            <td>
                {data.scheduled === false?(
                    <img className={classes.action_button_each_send_now} src={send_now} alt='Send Now'  id={index} onClick={props.handleSendNowCreative}/>
                    ):(
                    <img className={classes.action_button_each_send_now_sending} src={globe_gray} alt='Sending'  id={index} onClick={props.handleSendNowCreative}/>
                )}
            </td>
            <td>{data.screen_name === ""? "-":data.screen_name}</td>
            <td>{data.u_id === ""? "-":data.u_id}</td>


            <td className={classes.custom_name_field}>{data.screen_started}</td>
            <td>{data.screen_ended}</td>
            <td>{data.created_at}</td>
            <td>{data.updated_at}</td>
            <td>{data.dispatched}</td>
            <td>{data.dispatch_failed}</td>
            <td>{data.impressions}</td>
            <td>{data.clicks}</td>
            <td>
                <div>
                    <img className={classes.action_button_each} src={pencil} alt='Edit'  id={index} onClick={props.handleEditCreative}/>
                    <img className={classes.action_button_each} src={delete_red} alt='Delete'  id={index} onClick={props.handleDeleteCreative}/>
                </div>
            </td>
        </tr>
    )
    }

export default CreativesTableItem;