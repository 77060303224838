import classes from '../AddNewCreative/AddNewCreative.module.scss'

import CustomButton from '../Customs/CustomButton/CustomButton'
import CustomSelect from '../Customs/CustomSelect/CustomSelect'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

import {useSelector} from 'react-redux'
import { useEffect, useState } from 'react'
import { TimeOutHandler } from '../../utils/helper_functions';

import LoadingTable from '../LoadingTable/LoadingTable'



const AddEditFunnelTier = (props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    const targetFunnelTier = props.targetFunnelTier
    const names = useSelector(state => state.ui.language.values.automationContent.funnel_tier_add_edit)

    // all states
    const [loadingResults, setLoadingResults] = useState(true)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const [showFormSuccessAlert, setShowFormSuccessAlert] = useState(false)
    const [showFormAlert, setShowFormAlert] = useState(false)

    const [showFormAlertSuccessMsg, setShowFormAlertSuccessMsg] = useState('')
    const [showFormAlertMsg, setShowFormAlertMsg] = useState('')

    // website select
    const [newSelectedDomainIds, setNewSelectedDomainIds] = useState([])
    const [newSelectedDomains, setNewSelectedDomains] = useState([])


    // these ids will be removed as user deleted those while editing tier
    const [deleteWebsiteFromTierId, setDeleteWebsiteFromTierId] = useState([])
    const [deleteCreativeFromTierId, setDeleteCreativeFromTierId] = useState([])


    // creatives select 
    const [optionCreatives, setOptionCreatives] = useState([])
    const [newSelectedCreativesIds, setNewSelectedCreativesIds] = useState([])
    const [newSelectedCreatives, setNewSelectedCreatives] = useState([])


    // add fields 
    const [newTierName, setNewTierName] = useState('')

    
    
    const getExistingDataForTargetTier = async () => {
        // get Existing Data For Target Tier
        const response = await fetch(`${API_URL}/api/v1/getfunneltier`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_token}`
            },
        body: JSON.stringify({
            tier_id: targetFunnelTier.id,
            })
        })

        const res_status = response.status
        const res = await response.json()
        const data = res.res[0]

        // // console.log("\n\nexisting Data is ======", data,  data.existing_websites, data.existing_automations)
        if(res_status === 200) {
            const existing_websites = data['existing_websites']
            const existing_automations = data['existing_automations']

            var arr = []
            if(existing_websites.length>0){
                for(let i=0;  i<existing_websites.length; i++){
                    arr.push(existing_websites[i].id)
                }
                setNewSelectedDomainIds(arr)
                setNewSelectedDomains(data['existing_websites'])
            }

            var arr_auto = []
            if(existing_automations.length>0){
                for(let i=0;  i<existing_automations.length; i++){
                    arr_auto.push(existing_automations[i].id)
                }
                setNewSelectedCreativesIds(arr_auto)
                setNewSelectedCreatives(existing_automations)
            }
        }
    }

    
    // function section
    const closeSetShowAddEditFunnel = () =>{
        props.toggleAddEditFunnelTier()
    }

    useEffect(()=>{
        const getAllAutomatedCreative = async () =>{
            // get all automated creatives 
            const response = await fetch(`${API_URL}/api/v1/automatedcreatives`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${api_token}`
                },
            })

            const res_status = response.status
            const res = await response.json()
            if(res_status === 200) {
                setOptionCreatives(res.res)
            }
        }

        if(props.editFunnelTier){
            getExistingDataForTargetTier()
        }
        getAllAutomatedCreative()
        TimeOutHandler(()=>{
            setLoadingResults(false)
        }, 1200)
    }, [])


    useEffect(()=>{
        const getAvailableWebsites = async () =>{
            if(props.optionWebsites.length === 0){
                const response = await fetch(`${API_URL}/api/v1/availablewebsites`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${api_token}`
                    },
                })

                const res_status = response.status
                const res = await response.json()
                if(res_status === 200) {
                    props.setOptionWebsites(res.option_websites)
                }
            }
        }
        getAvailableWebsites()
    }, [])



    const websiteSelectHandler = (e) =>{
        var target_id = e.target.value

        // console.log("=======>   ", target_id, newSelectedDomainIds.includes(target_id), newSelectedDomainIds, newSelectedDomainIds.length, newSelectedDomains, newSelectedDomains.length)
        if(!newSelectedDomainIds.includes(target_id) && target_id!==''){
            setNewSelectedDomainIds([...newSelectedDomainIds, target_id])
            setNewSelectedDomains([...newSelectedDomains, props.optionWebsites.filter((element)=>(element.id === target_id))[0]])
        }else{ 
            console.log("Already existss :", target_id)
        }
    }

    const removeWebsiteSelectHandler = (e) =>{
        var target_id = e.target.id 
        var filteredArray = newSelectedDomainIds.filter((item) => item !== target_id)
        // // console.log('removeing a id', target_id, filteredArray)  
        setNewSelectedDomainIds(filteredArray)
        var filteredDomains = newSelectedDomains.filter((item)=> item.id !== target_id)
        setNewSelectedDomains(filteredDomains)

        setDeleteWebsiteFromTierId([...deleteWebsiteFromTierId, target_id])
    }





    const creativeSelectHandler = (e) =>{
        var target_id = e.target.value
        if(!newSelectedCreativesIds.includes(target_id) && target_id!==''){
            setNewSelectedCreativesIds([...newSelectedCreativesIds, target_id])
            setNewSelectedCreatives([...newSelectedCreatives, optionCreatives.filter((element)=> (element.id === target_id))[0]])
        }
    }

    const removeCreativeSelectHandler = (e) =>{
        var target_id = e.target.id 
        var filteredArray = newSelectedCreativesIds.filter((item) => item !== target_id)
        setNewSelectedCreativesIds(filteredArray)
        var filteredCreatives = newSelectedCreatives.filter((item)=> item.id !== target_id)
        setNewSelectedCreatives(filteredCreatives)

        setDeleteCreativeFromTierId([...deleteCreativeFromTierId, target_id])
    }


    const handleCreateEditFunnelTier = async () =>{
        setLoadingResults(true)
        setShowFormAlert(false)
        setShowFormSuccessAlert(false)

        var target_method;
        var target_body;

        if(props.editFunnelTier){
            target_method = "PUT"
            target_body = JSON.stringify({
                tier_id:targetFunnelTier.id,
                tier_name: newTierName,
                target_websites:newSelectedDomainIds,
                target_automation: newSelectedCreativesIds,
                websites_to_remove: deleteWebsiteFromTierId,
                automation_to_remove: deleteCreativeFromTierId,
            })
        }else{
            target_method = "POST"
            target_body = JSON.stringify({
                tier_name: newTierName,
                target_websites: newSelectedDomainIds,
                target_automation: newSelectedCreativesIds
            })
        }

        if((!props.editFunnelTier && newTierName.length>0) || (props.editFunnelTier && targetFunnelTier.id.length>0)){            
            const response = await fetch(`${API_URL}/api/v1/funneltier`, {
                method: target_method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${api_token}`
                },
                body: target_body
            })

            const res_status = response.status
            const res = await response.json()
            
            if(res_status === 200) {
                setLoadingResults(false)
                setShowFormAlertSuccessMsg(res.msg)
                setShowFormSuccessAlert(true)
                setShowCheckAnimation(true)

                TimeOutHandler(()=>{
                    setShowCheckAnimation(false)
                    props.setShowAddEditFunnelTier(false)
                    props.setEditFunnelTier(false)
                    props.setAddEditOpCompleted(true)
                }, 1500)

            }else{
                setLoadingResults(false)
                setShowFormAlertMsg(res.error)
                setShowFormAlert(true)
                
                setShowFailAnimation(true)
                TimeOutHandler(()=>{
                    setShowFailAnimation(false)
                }, 1500)
            }

        }else{
            setLoadingResults(false)
            if(props.editFunnelTier){
                setShowFormAlertMsg("Funnel Tier id is empty. Contact admin")
            }else{
                setShowFormAlertMsg("Funnel tier name can't be empty")
            }
            setShowFormAlert(true)

            TimeOutHandler(()=>{
                setShowFormAlert(false)
            }, 1500)
        }

    }




    return (
        <PopUpCard toggle={closeSetShowAddEditFunnel} header={props.editFunnelTier? names.title_edit : names.title_add}>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            {loadingResults && <LoadingTable />}

            <div className={classes.automation_fixed_height_div}>    
                <div className={classes.fixed_height_div_space_between}> 
                    <div className={classes.creative_info_div}>
                        <div className={classes.creative_info_div}>
                            {props.editFunnelTier &&(
                            <>
                                <div className={classes.creative_info_div_items_column}>
                                    <p><b>Tier Name : </b>{targetFunnelTier.tier_name}</p>
                                    <p><b>Target Websites : </b> ({targetFunnelTier.target_websites_len}) {targetFunnelTier.target_websites.join(', ')}</p>
                                    <p><b>Total Automations : </b> {targetFunnelTier.target_automation_len}</p>
                                </div>
                            </>)}
                            <>
                                <form className={`${classes.main_middle} ${props.editFunnelTier?classes.border_top_solid:classes.border_round_with_margin}`}>
                                    <div className={classes.middle_item}>
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.tier_name}</label>
                                            <div className={classes.middle_item_input}>
                                                <input type='text' placeholder={names.tier_name_placeholder} onChange={(e)=> setNewTierName(e.target.value)}/>
                                            </div>
            
                                            <label className={classes.middle_item_label_type_one_automtion}>{names.target_websites}</label>
                                            <div className={classes.middle_item_input_select_multiple}>
                                                <div className={classes.half_of_parent_select_div}>
                                                    {newSelectedDomains.length > 0 ? (
                                                        <div>
                                                            {newSelectedDomains.map((item, index) =>
                                                                <div onClick={removeWebsiteSelectHandler} className={classes.eachdomaindiv} id={item['id']} value={item['id']}>{item['domain']}</div>
                                                            )}
                                                        </div>
                                                    ):(<div>No Website selected</div>)}
                                                </div>

                                                <CustomSelect placeholder="Select Domains" value="Select" onChange={websiteSelectHandler}>
                                                    <option value=''>Select</option>
                                                    {props.optionWebsites.map((item, index) => <option value={item['id']}>{item['domain']}</option>)}
                                                </CustomSelect> 
                                            </div>

                                            <label className={classes.middle_item_label_type_one_automtion}>{names.target_automation}</label>
                                            <div className={classes.middle_item_input_select_multiple}>
                                                <div className={classes.half_of_parent_select_div}>
                                                    {newSelectedCreatives.length > 0 ? (
                                                        <div>
                                                            {newSelectedCreatives.map((item, index) => <div onClick={removeCreativeSelectHandler} className={classes.eachdomaindiv} id={item['id']} value={item['id']}>{item['title']}</div>)}
                                                        </div>
                                                    ):(<div>No Creatives selected</div>)}
                                                </div>

                                                <CustomSelect className={classes.custom_select} placeholder="Select Creatives" value="Select" onChange={creativeSelectHandler}>
                                                    <option value=''>Select</option>
                                                    {optionCreatives.map((item, index) => <option value={item['id']}>{item['title']}</option>)}
                                                </CustomSelect>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>
                        </div>
                    </div>
                </div>
                <div className={classes.buttons}>
                    {showFormSuccessAlert && <div>{showFormAlertSuccessMsg}</div>}
                    {showFormAlert && <div className={classes.formAlertDiv}>Error : {showFormAlertMsg}</div>}

                    <div className={classes.customButton}>
                        {props.editFunnelTier?(
                            <CustomButton reverse color='red' name={names.update_tier_btn} onClick={handleCreateEditFunnelTier}/>
                        ):(
                            <CustomButton reverse color='blue' name={names.create_tier_btn} onClick={handleCreateEditFunnelTier}/>
                        )}
                    </div>
                </div>
            </div>
        </PopUpCard>
    )
}

export default AddEditFunnelTier;