import classes from './AddNewCreative.module.scss'
import creative_classes from '../Creatives/Creatives.module.scss'

import CustomButton from '../Customs/CustomButton/CustomButton'
import CustomSelect from '../Customs/CustomSelect/CustomSelect'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'
import LoadingTable from '../LoadingTable/LoadingTable'

import {useSelector} from 'react-redux'
import { useEffect, useState } from 'react'
import { TimeOutHandler } from '../../utils/helper_functions';

import copy_green from '../../assets/icons/copy_green.svg'
import copy_gray from '../../assets/icons/copy_gray.svg'





const AddNewCreative = (props) => {
    const targetEditCreative = props.targetEditCreative
    const names = useSelector(state => state.ui.language.values.creativesContent.AddOrEditCreative)
    const creative_status = useSelector(state => state.ui.language.values.creativesContent.creative_status)
    const api_token = useSelector(state => state.user.token)
    const editCreative = props.editCreative
    
    const API_URL = process.env.REACT_APP_API_URL

    // edit creative section
    const [newDomain, setNewDomain] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [newLink, setNewLink] = useState('')
    const [newFavicon, setNewFavicon] = useState('')
    const [newImage, setNewImage] = useState('')
    const [newImageFile, setNewImageFile] = useState([])
    
    const [newDescription, setNewDescription] = useState('')
    const [newStatus, setNewStatus] = useState('')
    const [newTargetWebsites, setNewTargetWebsites] = useState('')
    const [newScheduleDate, setNewScheduleDate] = useState('')
    const [newScheduleOn, setNewScheduleOn] = useState('')
    
    const [automateChanged, setAutomateChanged] = useState(false)

    const [imageDisable, setImageDisable] = useState(false)
    const [imageFileDisable, setImageFileDisable] = useState(false)
    
    // form alert section
    const [showFormAlert, setShowFormAlert] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const [showSomethingWentWrongAlert, setShowSomethingWentWrongAlert] = useState(false)
    const [loadingResults, setLoadingResults] = useState(false)

    // create by url section
    // const [createByUrl, setCreateByUrl] = useState(false)
    const [disableCreateByUrl, setDisableCreateByUrl] = useState(false)
    // const [showFutureFeatureAlert, setShowFutureFeatureAlert] = useState(false) // delete after feature is developed


    // copy text green/gray icon state
    const [showGreenIcon, setShowGreenIcon] = useState(false)
    const [type, setType] = useState('')

    // charater left state
    const [titleLeft, setTitleLeft] = useState(256)
    const [desLeft, setDesLeft] = useState(512)
    const [favLeft, setFavLeft] = useState(256)
    const [imgLeft, setImgLeft] = useState(256)
    const [linkLeft, setLinkLeft] = useState(256)

    // calculate character left 
    useEffect(()=>{
        setTitleLeft(256-newTitle.length)
    }, [newTitle])
    useEffect(()=>{
        setFavLeft(256-newFavicon.length)
    }, [newFavicon])
    useEffect(()=>{
        setDesLeft(512-newDescription.length)
    }, [newDescription])
    useEffect(()=>{
        setLinkLeft(256-newLink.length)
    }, [newLink])
    useEffect(()=>{
        setImgLeft(256-newImage.length)
    }, [newImage])



    useEffect(()=>{
        setShowFormAlert(false)
        setShowSomethingWentWrongAlert(false)
        // setShowFutureFeatureAlert(false) // delete after feature is developed

        if(newDomain.length===0 && newTitle.length===0 && newLink.length===0 && newTargetWebsites.length===0 && newScheduleDate.length===0 && newScheduleOn.length===0){
            setDisableCreateByUrl(false)
        }else{
            setDisableCreateByUrl(true)
        }
    }, [newDomain, newTitle, newDescription, newLink, newImage, newScheduleDate, newScheduleOn, newStatus, newTargetWebsites])



    useEffect(()=>{
        // console.log("-------------", newImageFile, typeof newImageFile, newImageFile.length)
        if(newImage){setImageFileDisable(true)}else{setImageFileDisable(false)}
        if(newImageFile.length>0){setImageDisable(true)}else{setImageDisable(false)} 
    }, [newImageFile, newImage])

     // add or edit new creative section
     const handleAddNewCreative = async (e) =>{
        e.preventDefault()
        setShowFormAlert(false)
        setShowSomethingWentWrongAlert(false)
        setLoadingResults(true)
        // setShowFutureFeatureAlert(false) //delete after feature is developed

        if(editCreative){
            // console.log("Edit creative is true, lets update the existing creative :", 
            // targetEditCreative.domain, 
            // targetEditCreative.post_id,
            // "\ntitle :", newTitle.length===0, 
            // "\nlink :", newLink.length===0, 
            // "\nfav :", newFavicon.length===0, 
            // "\ndes :", newDescription.length===0, 
            // "\nimage :", newImage.length===0, 
            // "\nfile :", newImageFile.length===0,
            // "\nstatus :", newStatus.length===0,
            // "\nrun daily changed :", (props.runDailyChanged===false || newScheduleOn.length===0),
            // "\nautomate changed :", automateChanged===false,
            // "\nt websites :", newTargetWebsites.length===0,
            // "\n1 :", (props.runDaily?newScheduleOn.length===0:(newScheduleDate.length===0 || newScheduleOn.length===0)))

            if(newTitle.length===0 
                && newLink.length===0 
                && newFavicon.length===0 
                && newDescription.length===0
                && newImage.length===0
                && newImageFile.length===0
                && newStatus.length===0 
                && (props.runDailyChanged===false || newScheduleOn.length===0)
                && automateChanged===false
                && newTargetWebsites.length===0
                && (props.runDaily?newScheduleOn.length===0:(newScheduleDate.length===0 || newScheduleOn.length===0))
                ){
                    setShowFormAlert(true)
                    setLoadingResults(false)

                    TimeOutHandler(()=> {
                        setShowFormAlert(false)
                    }, 3000)
            }else{
                var updateObj = {
                    domain: targetEditCreative['domain'],
                    post_id: targetEditCreative['post_id'],
                    id: targetEditCreative['id'],
                    title: newTitle,
                    description: newDescription,
                    image: newImage,
                    link: newLink,
                    scheduled_date: newScheduleDate,
                    schedule_on: newScheduleOn,
                    target_websites: newTargetWebsites,
                    status: newStatus,
                    favicon: newFavicon,
                    run_daily: props.runDaily,
                    automate: props.automate,
                }

                let formdata = new FormData()
                formdata.append("updatedata", JSON.stringify(updateObj))
                if(imageDisable){formdata.append("filedata", newImageFile[0])}else{formdata.append("filedata", false)}

                const response = await fetch(`${API_URL}/api/v1/updatetargetcreative`, {
                        method: 'POST',
                        headers: {'Authorization': `Bearer ${api_token}`},
                        body: formdata,
                    })

                const res_status = response.status
                const data = await response.json()
                setLoadingResults(false)
                if(res_status === 200) {
                    setShowCheckAnimation(true)

                    console.log("add/update creative response is ===>", data.msg, data.success)
                    TimeOutHandler(() => {
                        closeAddNewCreative(true)
                        setShowCheckAnimation(false)
                    }, 1500)
                    props.setLoadingResults(true)
                    await props.getCreatives()
                    TimeOutHandler(() => {props.setLoadingResults(false)}, 1500)

                }else{
                    setShowFailAnimation(true)
                    setShowSomethingWentWrongAlert(true)
                    console.log("=== Error in update articles ===> ", res_status, data.success, data.error)
                    TimeOutHandler(() => {setShowFailAnimation(false)}, 1500)
                    TimeOutHandler(() => {setShowSomethingWentWrongAlert(false)}, 3000)
                }
            }


        // ######################################## add new creative section ########################################
        }else{
            if(newDomain.length>0 && newTitle.length>0 && newLink.length>0 && newTargetWebsites.length>0 && newStatus.length>0 && newScheduleOn.length>0){
                if(!props.runDaily){
                    if (newScheduleDate.length>0){
                        console.log("all good")
                    }else{
                        setShowFormAlert(true)
                        setLoadingResults(false)
                        return
                    }
                }
                var creativeObj = {
                    domain: newDomain,
                    title: newTitle,
                    image: newImage,
                    description: newDescription,
                    link: newLink,
                    scheduled_date: newScheduleDate,
                    schedule_on: newScheduleOn,
                    target_websites: newTargetWebsites,
                    status: newStatus,
                    run_daily: props.runDaily,
                    automate: props.automate,
                }   

                let formdata = new FormData()
                formdata.append("data", JSON.stringify(creativeObj))

                // if(imageDisable){formdata.append("filedata", newImageFile)}else{formdata.append("filedata", false)}
                if(imageDisable){
                    for (let i = 0; i < newImageFile.length; i++) {
                        formdata.append('file', newImageFile[i])
                    }
                }

                if(!imageDisable && !imageFileDisable){
                    setLoadingResults(false)
                    setShowFormAlert(true)
                    TimeOutHandler(()=> {
                        setShowFormAlert(false)
                    }, 4000)
                }else{
                    const response = await fetch(`${API_URL}/api/v1/createnewcreative`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${api_token}`},
                            body:formdata
                        })

                    const res_status = response.status
                    const data = await response.json()
                    setLoadingResults(false)
                    if(res_status === 200) {
                        setShowCheckAnimation(true)
                        console.log("add new creative response is is ===>", data.msg, data.success, data.delete_count)
                        TimeOutHandler(() => {
                            closeAddNewCreative(true)
                            setShowCheckAnimation(false)
                        }, 1500)
                        props.setLoadingResults(true)
                        await props.getCreatives()
                        TimeOutHandler(() => {props.setLoadingResults(false)}, 1500)

                    }else{
                        setShowFailAnimation(true)
                        setShowSomethingWentWrongAlert(true)
                        console.log("Error in add new creative ===> ", res_status, data.success, data.error, data.delete_count)
                        TimeOutHandler(() => {setShowFailAnimation(false)}, 1500)
                    }
                }

            }else if(!disableCreateByUrl){
                // setShowFutureFeatureAlert(true)
                setLoadingResults(false)
            }else{
                setShowFormAlert(true)
                setLoadingResults(false)
            }
        }
    }





    const closeAddNewCreative = () =>{
        props.setShowAddNewCreative(false)
        props.setRunDaily(false)

        if(editCreative){
            props.setTargetEditCreative('')
            props.setEditCreative(false)
            setNewTitle('')
            setNewLink('')
            setNewFavicon('')
            setNewImage('')
            setNewDescription('')
            setNewTargetWebsites('')
            setNewStatus('')
            setNewScheduleDate('')
            setNewScheduleOn('')
        }
    }


    // on/off displaying a creative in automation tab
    const toggleAutomate = (e) =>{
        props.setAutomate(e.target.checked)
        setAutomateChanged(true)
    }


    const go_back_to_initial_state = () => {
        setNewTitle('')
        setNewDescription('')
        setNewFavicon('')
        setNewLink('')
        setNewScheduleDate('')
        setNewScheduleOn('')
        setNewImage('')
        setNewImageFile([])
        setNewTargetWebsites('')

    }




    return (
            <PopUpCard toggle={closeAddNewCreative} header={editCreative ? (names.title_edit):(names.title)}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                {loadingResults && <LoadingTable />}

                {editCreative ? (
                <>
                    <div className={classes.fixed_height_div}>
                        <div className={classes.fixed_height_div_space_between}>
                            <>                    
                                <div className={classes.creative_info_div}>
                                    <div className={classes.creative_info_div_items}>
                                        <div className={classes.creative_info_div_items_left}>
                                            <p><b>● Domain : </b>{targetEditCreative.domain}</p>
                                            <p className={classes.flex_row_start_center}><b>● Title : </b>{targetEditCreative.title_default}<img className={classes.padding_row} src={showGreenIcon && type==='title'?copy_green:copy_gray} onClick={()=>{setNewTitle(targetEditCreative.title_default)}} onMouseEnter={() => {setShowGreenIcon(true); setType('title')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                            

                                            <p className={classes.flex_row_start_start}>
                                                    <div>
                                                        <p><b>● Description : </b>{targetEditCreative.description_default}</p>
                                                    </div>
                                                    <div>
                                                        <img className={classes.padding_row} src={showGreenIcon && type==='des'?copy_green:copy_gray} onClick={()=>{setNewDescription(targetEditCreative.description_default)}} onMouseEnter={() => {setShowGreenIcon(true); setType('des')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/>
                                                    </div>
                                                </p>
                                            
                                            <p className={classes.flex_row_start_center}><b>● Link : </b><a target="_blank" rel="noreferrer" href={targetEditCreative.link_default}>{targetEditCreative.link_default}</a><img className={classes.padding_row} src={showGreenIcon && type==='link'?copy_green:copy_gray} onClick={()=>{setNewLink(targetEditCreative.link_default)}} onMouseEnter={() => {setShowGreenIcon(true); setType('link')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                            <p className={classes.flex_row_start_center}><b>● Target Websites : </b><b>[</b>{targetEditCreative.target_websites}<b>]</b><img className={classes.padding_row} src={showGreenIcon && type==='tweb'?copy_green:copy_gray} onClick={()=>{setNewTargetWebsites(targetEditCreative.target_websites)}} onMouseEnter={() => {setShowGreenIcon(true); setType('tweb')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                        </div>
                                        <div className={classes.creative_info_div_items_right}>
                                            <div className={classes.creative_info_div_items_right_left}>
                                                <p className={classes.flex_row_start_center_img}><b>● Favicon</b><img className={classes.padding_row} src={showGreenIcon && type==='favicon'?copy_green:copy_gray} onClick={()=>{setNewFavicon(targetEditCreative.favicon)}} onMouseEnter={() => {setShowGreenIcon(true); setType('favicon')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                                <a target="_blank" rel="noreferrer" href={targetEditCreative.favicon}><img className={classes.creative_info_div_items_right_favicon} src={targetEditCreative.favicon} alt='edit_image'/></a>
                                            </div>
                                            <div className={classes.creative_info_div_items_right_right}>
                                                <p className={classes.flex_row_start_center_img}><b>● Image</b><img className={classes.padding_row} src={showGreenIcon && type==='img'?copy_green:copy_gray} onClick={()=>{setNewImage(targetEditCreative.image)}} onMouseEnter={() => {setShowGreenIcon(true); setType('img')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                                <a target="_blank" rel="noreferrer" href={targetEditCreative.image}><img className={classes.creative_info_div_items_right_image} src={targetEditCreative.image} alt='edit_image'/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <form className={`${classes.main_middle} ${classes.border_top_solid}`}>
                                    <div className={classes.middle_item}> 
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeTitle} {(titleLeft < 256 && titleLeft>0) ? <small>{`(${titleLeft} characters left)`}</small>:<small className={creative_classes.red_text}>{newTitle===''?'':'(limit exceed)'}</small>}</label>
                                            <div className={classes.middle_item_input}>
                                                <input value={newTitle} placeholder={names.creativeTitlePlaceholder} onChange={(e)=> setNewTitle(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeLink} {(linkLeft < 256 && linkLeft>0) ? <small>{`(${linkLeft} characters left)`}</small>:<small className={creative_classes.red_text}>{newLink===''?'':'(limit exceed)'}</small>}</label>
                                            <div className={classes.middle_item_input}>
                                                <input value={newLink} onChange={(e)=> setNewLink(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.middle_item}> 
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeFavicon} {(favLeft < 256 && favLeft>0) ? <small>{`(${favLeft} characters left)`}</small>:<small className={creative_classes.red_text}>{newFavicon===''?'':'(limit exceed)'}</small>}</label>
                                            <div className={classes.middle_item_input}>
                                                <input value={newFavicon} onChange={(e)=> setNewFavicon(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeDescription} {(desLeft < 512 && desLeft>0) ? <small>{`(${desLeft} characters left)`}</small>:<small className={creative_classes.red_text}>{newDescription===''?'':'(limit exceed)'}</small>}</label>
                                            <div className={classes.middle_item_input}>
                                                <input value={newDescription} onChange={(e)=> setNewDescription(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.middle_item}>
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeImage} {(imgLeft < 256 && imgLeft>0) ? <small>{`(${imgLeft} characters left)`}</small>:<small className={creative_classes.red_text}>{newImage===''?'':'(limit exceed)'}</small>}</label>
                                            <div className={classes.middle_item_input}>
                                                {imageDisable ? (<input placeholder='disabled' disabled/>
                                                ):(<input value={newImage}  onChange={(e)=> setNewImage(e.target.value)}/>)}
                                            </div>
                                        </div>
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeImageByUpload}</label>
                                            <div className={classes.middle_item_input}>
                                                {imageFileDisable ? (<input type="file" accept="image/*" placeholder='disabled'  disabled/>
                                                ):(<input type="file" accept="image/*" placeholder={names.creativeImagePlaceholder} onChange={(e)=> setNewImageFile(e.target.files)}/>)} 
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className={classes.middle_item_flex_column_select}> */}
                                    <div className={classes.middle_item}> 
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeStatus}</label>

                                            <div className={classes.three_item_div}>
                                                <div className={classes.middle_item_input_without_padding}>
                                                    <CustomSelect onChange={(e)=> setNewStatus(e.target.value)} value={newStatus.length>0 ? newStatus : targetEditCreative.status}>
                                                        {targetEditCreative.status==="RUNNING"?(
                                                            <>
                                                                <option value=''>↓ Select Status</option>
                                                                <option value='INTERRUPT'>{creative_status.interrupt}</option>
                                                            </>
                                                        ):(<>
                                                            {targetEditCreative.status==="INTERRUPT"?(<></>):(
                                                                <>
                                                                    <option value=''>↓ Select Status</option>
                                                                    <option value='ACTIVE'>{creative_status.active}</option>
                                                                    <option value='INACTIVE'>{creative_status.inactive}</option>
                                                                </>
                                                                )}
                                                        </>)}
                                                    </CustomSelect>
                                                </div>

                                                <div className={classes.middle_item_flex_row_custom}>
                                                    <label className={classes.middle_item_label_type_one}>{names.run_daily}</label>
                                                    <input className={classes.checkbox_daily} checked={props.runDaily} type='checkbox' onChange={props.toggleRunDaily}/>
                                                </div>
                                                <div className={classes.middle_item_flex_row_custom}>
                                                    <label className={classes.middle_item_label_type_one}>{names.automate}</label>
                                                    <input className={classes.checkbox_daily} checked={props.automate} type='checkbox' onChange={toggleAutomate}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.creativeTargetWebsites}</label>
                                            <div className={classes.middle_item_input}>
                                                <input value={newTargetWebsites} onChange={(e) => setNewTargetWebsites(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.middle_item}>
                                        {!props.runDaily && (

                                            <div className={classes.middle_item_flex_column}>
                                                <label className={classes.middle_item_label_type_one}>{names.schedule_date}</label>
                                                <div className={classes.middle_item_input}>
                                                    <input type='date' placeholder='yy/mm/dd' value={newScheduleDate}  onChange={(e)=> setNewScheduleDate(e.target.value)}/>
                                                </div>
                                            </div>
                                        )}
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.schedule_on}</label>
                                            <div className={classes.middle_item_input}>
                                                <input type='time' placeholder='hh:mm:ss' value={newScheduleOn}  onChange={(e)=> setNewScheduleOn(e.target.value)}/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </>
                        </div>
                    </div>

                    <div className={creative_classes.add_images_btn_div}>
                        <div className={creative_classes.add_images_btn_div_each}>
                            <CustomButton color='red' name={names.reset} onClick={go_back_to_initial_state}/>

                            <div className={classes.formAlertDiv}>
                                {showSomethingWentWrongAlert && <div className={classes.formAlertDiv}>Something went wrong. Please try again later</div>}
                                {showFormAlert && <div className={classes.formAlertDiv}>All items can't be empty. Kindly check the values</div>}
                            </div>
                        </div>
                        <div className={creative_classes.add_images_btn_div_each}>
                            <div className={classes.customButton}>
                                <CustomButton reverse color='red' name={editCreative ? (names.btn_edit):(names.btn)} type='submit' onClick={handleAddNewCreative}/>
                            </div>
                        </div>
                    </div>
                </>
                ):(
                <>
                    <div className={classes.fixed_height_div}>
                        <div className={classes.fixed_height_div_space_between}>
                            <form className={`${classes.main_middle} ${classes.border_round} ${classes.main_middle_mt1}`}>
                                <div className={classes.middle_item}> 
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeDomain}</label>
                                        <div className={classes.middle_item_input}>
                                            <input placeholder={names.creativeDomainPlaceholder}  onChange={(e)=> setNewDomain(e.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeTitle}</label>
                                        <div className={classes.middle_item_input}>
                                            <input placeholder={names.creativeTitlePlaceholder}  onChange={(e)=> setNewTitle(e.target.value)} required/>
                                        </div>
                                    </div>
                                </div>


                                <div className={classes.middle_item}> 
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeLink}</label>
                                        <div className={classes.middle_item_input}>
                                            <input placeholder={names.creativeLinkPlaceholder}  onChange={(e)=> setNewLink(e.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeDescription}</label>
                                        <div className={classes.middle_item_input}>
                                            <input placeholder={names.creativeDescriptionPlaceholder}  onChange={(e)=> setNewDescription(e.target.value)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.middle_item}>
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeImage}</label>
                                        <div className={classes.middle_item_input}>
                                            {imageDisable ? (<input placeholder='disabled' disabled />
                                            ):(<input placeholder={names.creativeImagePlaceholder}  onChange={(e)=> setNewImage(e.target.value)}/>)}
                                        </div>
                                    </div>
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeImageByUpload}</label>
                                        <div className={classes.middle_item_input}>
                                            {imageFileDisable ? (<input type="file" accept="image/*" placeholder='disabled'  disabled/>
                                            ):(<input type="file" accept="image/*" placeholder={names.creativeImagePlaceholder} onChange={(e)=> setNewImageFile(e.target.files)}/>)}
                                        </div>
                                    </div>
                                </div>


                                <div className={classes.middle_item}> 
                                    <div className={classes.middle_item_flex_column_select}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeStatus}</label>

                                        <div className={classes.three_item_div}>
                                            <div className={classes.middle_item_input_without_padding_rounded}>
                                                <CustomSelect onChange={(e)=> setNewStatus(e.target.value)} value={newStatus} required>
                                                    <option value=''>↓ Select Status</option>
                                                    <option value='ACTIVE'>{creative_status.active}</option>
                                                    <option value='INACTIVE'>{creative_status.inactive}</option>
                                                </CustomSelect>
                                            </div>
                                            <div className={classes.middle_item_flex_row_custom}>
                                                <label className={classes.middle_item_label_type_one}>{names.run_daily}</label>
                                                <input className={classes.checkbox_daily} type='checkbox' onChange={props.toggleRunDaily}/>
                                            </div>
                                            <div className={classes.middle_item_flex_row_custom}>
                                                <label className={classes.middle_item_label_type_one}>{names.automate}</label>
                                                <input className={classes.checkbox_daily} type='checkbox' onChange={toggleAutomate}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeTargetWebsites}</label>
                                        <div className={classes.middle_item_input}>
                                            <input placeholder={targetEditCreative.target_websites} onChange={(e) => setNewTargetWebsites(e.target.value)} required/>
                                        </div>
                                    </div>
                                </div>



                                <div className={classes.middle_item}>
                                    {!props.runDaily && (
                                        <div className={classes.middle_item_flex_column}>
                                            <label className={classes.middle_item_label_type_one}>{names.schedule_date}</label>
                                            <div className={classes.middle_item_input}>
                                                <input type='date' placeholder='yy/mm/dd' onChange={(e)=> setNewScheduleDate(e.target.value)} required/>
                                            </div>
                                        </div>
                                    )}
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.schedule_on}</label>
                                        <div className={classes.middle_item_input}>
                                            <input type='time' placeholder='hh:mm:ss'  onChange={(e)=> setNewScheduleOn(e.target.value)} required/>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className={classes.orDiv}>Or</div> */}
                                {/* create creative by url section */}
                                {/* <div className={classes.middle_item}> 
                                    <div className={classes.middle_item_flex_column}>
                                        <label className={classes.middle_item_label_type_one}>{names.creativeByUrl}</label>
                                        <div className={classes.middle_item_input}>
                                            {disableCreateByUrl ? (
                                            <input placeholder='Disabled' disabled/>
                                            ):(
                                            <input placeholder='Enter url to create creative'  onChange={(e)=> setCreateByUrl(e.target.value)} required/>)}
                                        </div>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    <div className={classes.add_images_btn_div}>
                        <div className={creative_classes.add_images_btn_div_each_right}>

                            {showSomethingWentWrongAlert && <div className={classes.formAlertDiv}>Something went wrong. Please try again later</div>}
                            {showFormAlert && <div className={classes.formAlertDiv}>Form items can't be empty. Kindly check the values</div>}
                            
                            <div className={classes.customButton}>
                                <CustomButton reverse color='red' name={editCreative ? (names.btn_edit):(names.btn)} type='submit' onClick={handleAddNewCreative}/>
                            </div>
                        </div>
                    </div>
                </>
                )}
        </PopUpCard>
    )
}

export default AddNewCreative;