// import classes from './WebsiteControl.module.scss'
import classes from '../Creatives/Creatives.module.scss';
import edit_classes from '../AddNewCreative/AddNewCreative.module.scss'

import CustomButton from "../Customs/CustomButton/CustomButton";
import Pagination from '../Pagination/Pagination';
import HeadersAllWebsites from './HeadersAllWebsites';
import WebsiteControlTableItem from './WebsiteControlTableItem/WebsiteControlTableItem';
import AddNewDomain from '../AddNewDomain/AddNewDomain';
import SearchBar from '../SearchBar/SearchBar';
import AmountSelect from '../AmountSelect/AmountSelect';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable'
import CustomSelect from '../Customs/CustomSelect/CustomSelect';

import copy_white from '../../assets/icons/copy_white.svg'
// import delete_gray from '../../assets/icons/delete_gray.svg'

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {Link } from 'react-router-dom'
import { clearAllTimeouts, fullYearParser, hoursMinutesParser, TimeOutHandler} from '../../utils/helper_functions';

import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation'
import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation'
import WebsiteControlTableItemClicks from './WebsiteControlTableItem/WebsiteControlTableItemClicks';
import HeadersAllWebsitesClicks from './HeadersAllWebsitesClicks';
import PopUpCard from '../Cards/PopUpCard/PopUpCard';

import LoadingTable from '../LoadingTable/LoadingTable'


const WebsiteControl = () => {
    // required variables and names
    const names = useSelector(state => state.ui.language.values.websitesContent)
    const creative_names = useSelector(state => state.ui.language.values.creativesContent)

    const names_from_suscribers = useSelector(state => state.ui.language.values.subscribers)
    const creative_table_headers = creative_names.table_headers
    const click_headers = creative_names.clicks_table_headers



    const website_table_headers = names.table_headers
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)

    // pagination section
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)
    
    
  
    // data store section
    const [Data, setData] = useState([])

    let csv_name = new Date()
    csv_name = `${fullYearParser(csv_name)}-${hoursMinutesParser(csv_name)}`

    // add new websites section
    const [showAddNewWebsite, setShowAddNewWebsite] = useState(false) 
    const [editWebsite, setEditWebsite] = useState(false) 
    const [targetEditWebsite, setTargetEditWebsite] = useState('') 

    // delete websites
    const [showDeleteWebsiteConfirmation, setShowDeleteWebsiteConfirmation] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    
    const [bulkDelete, setBulkDelete] = useState(false)
    const [targetIdToDelete, setTargetIdToDelete] = useState(0)
    const [showDeleteErrorAlert, setShowDeleteErrorAlert] = useState(false)
    
    
    // query for search query
    const [queryField, setQueryField] = useState('domain')
    const [queryValue, setQueryValue] = useState('')

    // select one or multiple or all
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [checkedNumber, setCheckedNumber] = useState(0)


    const [createdDomains, setCreatedDomains] = useState([])
    const [notCreatedDomains, setNotCreatedDomains] = useState([])
    const [showsetNotCreatedDomainsError, setShowsetNotCreatedDomainsError] = useState(false)
    const [showAddWebsiteMsg, setShowAddWebsiteMsg] = useState(false)
    
    // schedule now sections
    const [scheduleNowDomain, setScheduleNowDomain] = useState('')
    const [showScheduleNowMsg, setShowScheduleNowMsg] = useState(false)
    const [showScheduleNowErrorMsg, setShowScheduleNowErrorMsg] = useState(false)
    

    // verify now sections
    const [verifyNowDomain, setVerifyNowDomain] = useState('')
    const [showVerifyNowMsg, setShowVerifyNowMsg] = useState(false)
    const [showVerifyNowErrorMsg, setShowVerifyNowErrorMsg] = useState(false)
    
    // download sw.js section
    const [showDownloadSwSuccess, setShowDownloadSwSuccess] = useState(false)
    const [showDownloadSwError, setShowDownloadSwError] = useState(false)

    const [showScheduleToggleMsg, setShowScheduleToggleMsg] = useState(false)
    const [showScheduleToggleMsgError, setShowScheduleToggleMsgError] = useState(false)


     // show clicks data table
     const [byQuery, setByQuery] = useState(false)
     const [showWebsitesDiv, setShowWebsitesDiv] = useState(true)
     const [ClickData, setClickData] = useState([])
     const [startDate, setStartDate] = useState('')
     const [endDate, setEndDate] = useState('')
     const [showDateError, setShowDateError] = useState(false)
     const [showByQueryDiv, setShowByQueryDiv] = useState(false)


    // clipboard div status
    const [showClipboardDiv, setShowClipboardDiv] = useState(false)
    const [clipboardTitle, setClipboardTitle] = useState('')
    const [clipboardType, setClipboardType] = useState('')
    const [clipboardBtnColor, setClipboardBtnColor] = useState('')
    const [clipboardScripttext, setClipboardScripttext] = useState('')
    const [showCopiedAlert, setShowCopiedAlert] = useState(false)


    // handle chekc and select one /all section
    useEffect(()=>{
        setCheckedNumber(isCheck.length)
    },[isCheck])

    const handleSelectAll = e => {       
        setIsCheckAll(!isCheckAll);
        setIsCheck(Data.map((item, index) => index));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
    };



    // get websites using query or  without query section
    useEffect(() => {
        setQueryValue('');
    }, [queryField]);


    useEffect(async () => {
        if(queryValue.length === 0) {
            setLoadingResults(true)
            await getWebsites()
            setLoadingResults(false)
        }
        else {
            clearAllTimeouts()
            TimeOutHandler(async() => {
                setLoadingResults(true)
                await getWebsites()
                setLoadingResults(false)
            }, 600)
        }
    },[pageNumber, numberOfResults, queryValue])


   
    const getWebsites = async () => {
        const response = await fetch(`${API_URL}/api/v1/getpushwebsites`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: pageNumber,
                items: numberOfResults,
            })
        })
        
        const res_status = response.status
        const data = await response.json()
       
        if(res_status === 200) {
            var total_entires = data.total_website_count
            setData(data.res)
            setNumberOfTotalEntries(total_entires)
            setNumberOfResultsAfterQuery(data.website_count)

            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }

            TimeOutHandler(()=>{
                setShowAddWebsiteMsg(false)
                setShowsetNotCreatedDomainsError(false)
            }, 5000)
        }
    }


    const handleSearchChange = (e) =>{
        var val = e.target.value
        setQueryValue(val)
    }


    const handleDeleteEachWebsite = (e) =>{
        const { id } = e.target;
        setTargetIdToDelete(parseInt(id))
        setShowDeleteWebsiteConfirmation(true)
    }
    const handleBulkDelete = () =>{
        setShowDeleteWebsiteConfirmation(true)
        setBulkDelete(true)
    }
    

    const closeDeleteConfirmationHandler = () => {
        setShowDeleteWebsiteConfirmation(false)
    }

    const goBackToInitialDeleteState = () =>{
        setTargetIdToDelete('')   
        setBulkDelete(false)
        setIsCheckAll(false)
        setIsCheck([])
        setCheckedNumber(0)

        TimeOutHandler(async() => {
            setShowCheckAnimation(false)
            setShowFailAnimation(false)
            setLoadingResults(true)
            await getWebsites()
            setLoadingResults(false)
        }, 1200)
    }

    const deleteTargetWebsites = async () => {
        setShowDeleteWebsiteConfirmation(false)
        setLoadingResults(true)
        
        var deleteTargetWebsitesData = []
        if(bulkDelete){
            for(let i=0;  i<isCheck.length; i++){
                var each_website = Data[isCheck[i]]
                var eachWebsiteToDelete = {
                    id:each_website['id'],
                    domain:each_website['domain'],
                    verified:each_website['verified']
                }
                deleteTargetWebsitesData.push(eachWebsiteToDelete)
            }

        }else{
            var target_website_to_delete = Data[targetIdToDelete]

            var eachWebsiteToDelete = {
                id:target_website_to_delete['id'],
                domain:target_website_to_delete['domain'],
                verified:target_website_to_delete['verified']
            }
            deleteTargetWebsitesData.push(eachWebsiteToDelete)
        }

        const response = await fetch(`${API_URL}/api/v1/managewebsites`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                deleteWebsiteData: deleteTargetWebsitesData,
            })
        })
        
        setLoadingResults(false)
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            setShowCheckAnimation(true)
            console.log("delete website response is is ===>", data.msg, data.deleted, data.not_exists)
            goBackToInitialDeleteState()
        }
        else{
            console.log("=== Error in delete website ===> ", res_status, data.success, data.error, data.delete_count)
            setShowDeleteErrorAlert(true)
            setShowFailAnimation(true)
            TimeOutHandler(()=>{
                setShowDeleteErrorAlert(false)
            }, 4000)
            goBackToInitialDeleteState()
        }
    }
   
    const handleAddNewWebsite = () => {
        setShowAddNewWebsite(true)
        setShowAddWebsiteMsg(false)
        setShowsetNotCreatedDomainsError(false)
    }

    const handleEditWebsite = (e) =>{
        const { id } = e.target;
        setTargetEditWebsite(Data[id])
        setEditWebsite(true)
        setShowAddNewWebsite(true)
    }

    const toggle = () =>{
        setShowAddNewWebsite(!showAddNewWebsite)
    }

    const childPropsForAddNewWebsite = { 
        getWebsites, 
        setLoadingResults,
        toggle, 
        setEditWebsite,
        editWebsite,
        targetEditWebsite,
        setTargetEditWebsite,
        createdDomains,
        setCreatedDomains,
        notCreatedDomains,
        setNotCreatedDomains,
        showsetNotCreatedDomainsError,
        setShowsetNotCreatedDomainsError,
        showAddWebsiteMsg,
        setShowAddWebsiteMsg,
     };


     const handleScheduleNow = async (e) =>{
        setLoadingResults(true)
        const {id} = e.target;
        var targetScheduleNowObj = Data[id]
        
        setScheduleNowDomain(targetScheduleNowObj['domain'])

        const response = await fetch(`${API_URL}/api/v1/websiteschedulenow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                scheduleNowWebsite:[targetScheduleNowObj],
            })
        })
        
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            console.log("schedule now response is ===>", data.scheduled_domains, data.scheduled, data.not_scheduled)
            setShowScheduleNowMsg(true)

            await getWebsites()
            setLoadingResults(false)
            
            TimeOutHandler(()=>{
                setShowScheduleNowMsg(false)
            }, 5000)
        }
        else{
            setShowScheduleNowErrorMsg(true)
            setLoadingResults(false)
            TimeOutHandler(()=>{
                setShowScheduleNowErrorMsg(false)
            }, 5000)
            console.log("=== Error in schedule now website ===> ", res_status, data.error)
        }
    }


    const handleVerifyNow = async (e) =>{
        setLoadingResults(true)
        const {id} = e.target;
        var targetVerifyNowObj = Data[id]
        
        setVerifyNowDomain(targetVerifyNowObj['domain'])

        const response = await fetch(`${API_URL}/api/v1/verifynowwebsite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                verifyNowWebsite:[targetVerifyNowObj],
            })
        })
        
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            console.log("verify now response is ===>", data.verified_domains, data.verified, data.not_verified)
            setShowVerifyNowMsg(true)

            await getWebsites()
            setLoadingResults(false)
            
            TimeOutHandler(()=>{
                setShowVerifyNowMsg(false)
            }, 5000)
        }
        else{
            setShowVerifyNowErrorMsg(true)
            setLoadingResults(false)
            TimeOutHandler(()=>{
                setShowVerifyNowErrorMsg(false)
            }, 5000)
            console.log("=== Error in verify now website ===> ", res_status, data.error)
        }
    }



    const handleShowWebsiteDiv = () =>{
        setShowWebsitesDiv(true)
    }

    const importWebsiteClicksData = async () =>{
        if(byQuery===true){
            if(startDate.length<=0 || endDate.length<=0){
                setShowDateError(true)
                return
            }
        }
        
        setShowWebsitesDiv(false)
        setLoadingResults(true)
        const response = await fetch(`${API_URL}/api/v1/websiteclicks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber,
                byquery:byQuery,
                start_date:startDate,
                end_date:endDate
                })
            })
            
            const res_status = response.status
            const data = await response.json()
            console.log("websites clicks data returned is ===>", res_status, data.res, data.clicks_data_count, data.total_entries, numberOfResults)
           
            if(res_status === 200) {
                const total_entires = data.total_entires
                setNumberOfTotalEntries(total_entires)
                setClickData(data.res)
                setNumberOfResultsAfterQuery(data.clicks_data_count)
    
                if(numberOfResults > total_entires){
                    setNumberOfPages(1)
                }else{
                    setNumberOfPages(Math.ceil(total_entires/numberOfResults))
                }
                
                if(byQuery===true){
                    setShowByQueryDiv(true)
                }else{
                    setShowByQueryDiv(false)
                    setStartDate('')
                    setEndDate('')
                }
            }
            setLoadingResults(false)
        }


    const handleChangeStartDate = (e) =>{
        setStartDate(e.target.value)
        setByQuery(true)
        setShowDateError(false)
    }

    const handleChangeEndDate = (e) =>{
        setEndDate(e.target.value)
        setByQuery(true)
        setShowDateError(false)
    }

    const handleSearchByDate = async (e) => {
        setByQuery(true)
        setLoadingResults(true)
        await importWebsiteClicksData()
        setLoadingResults(false)
    }

    const handleShowClicksAgain = async (e) => {
        setByQuery(false)
        setLoadingResults(true)
        await importWebsiteClicksData()
        setLoadingResults(false)
    }



    // #################################### clipboard div ####################################
    const toggleClipboardDiv = (e) => {
        const target_type = e.target.id

        if(!showClipboardDiv){get_js_file(target_type)}
        if(target_type==='body'){
            setClipboardTitle('Body Script Setup')
            setClipboardBtnColor('purple')

        }else if(target_type==='push'){
            setClipboardTitle('Push Notification Installation')
            setClipboardBtnColor('red')

        }else if(target_type==='sw'){
            setClipboardTitle('Service Worker Implementation')
            setClipboardBtnColor('orange')
        }

        setClipboardType(target_type)
        setShowClipboardDiv(!showClipboardDiv)
        setShowCopiedAlert(false)
    }


    const get_js_file = async (target_type) => {
        setLoadingResults(true)
        const response = await fetch(`${API_URL}/api/v1/getjsfile`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({file_name: target_type+".js",})
        })
            
        const res_status = response.status
        const data = await response.json()        
        if(res_status === 200) {
            setClipboardScripttext(data.file_data)
        }else{
            setClipboardScripttext('Error occurred')
        }
        setLoadingResults(false) 
    }



    const handleCopyToClipboard = () => {
        setShowCopiedAlert(true)
        navigator.clipboard.writeText(clipboardScripttext)

        TimeOutHandler(()=>{
            setShowCopiedAlert(false)
        }, 1500)
    }
    


    return (
        <>  
        {/* clipboard div component */}
        {showClipboardDiv && <PopUpCard toggle={toggleClipboardDiv} header={clipboardTitle}>
            <div className={classes.clipboard_div}>
                <div className={classes.clipboard_div_items}>
                    <div className={classes.clipboard_div_top}>
                        {clipboardType === 'body' && <p>{`1. Copy and paste this code in the <body> tag`}</p>}
                        {clipboardType === 'push' && <p>1. Download push.js file <b className={classes.big_text}>or</b> Copy & paste the following code in <b>push.js</b> file inside <b>public_html</b> folder. Path: <code className={classes.bg_input_div}>public_html/push.js</code></p>}
                        {clipboardType === 'sw' && <p>1. Download sw.js file <b>or</b> Copy & paste the following code in <b>sw.js</b> file inside <b>public_html</b> folder. Path: <code className={classes.bg_input_div}>public_html/sw.js</code></p>}
                        <p>2. Clear any website cache</p>
                    </div>

                    <div className={classes.clipboard_div_middle}>
                        {showCopiedAlert && 
                            <div className={classes.middleAlert} onClick={()=>setShowCopiedAlert(false)}>
                                <img src={copy_white} alt='copied'/>
                                <p>Copied!</p>
                            </div>}
                        {loadingResults ? (<LoadingTable/>):(<code>{clipboardScripttext}</code>)}
                    </div>
                </div>

                {/* button section */}
                <div className={classes.clipboard_div_bottom}>
                    <div className={classes.add_images_btn_div_each_right}>
                        <Link to={`/static/${clipboardType}.js`} target="_blank" download>
                            <div className={edit_classes.customButton}>
                                <CustomButton color='green' name={names.download}/>
                            </div>
                        </Link>
                        <div className={edit_classes.customButton}>
                            <CustomButton color={clipboardBtnColor} name={names.copy_to_clipboard} onClick={handleCopyToClipboard}/>
                        </div>
                    </div>
                </div>
            </div>
        </PopUpCard>}

        {showAddNewWebsite && <AddNewDomain {...childPropsForAddNewWebsite}/>}
        {showDeleteWebsiteConfirmation && <DeleteConfirmation delete_title={names.delete_title} onYes={deleteTargetWebsites} onNo={closeDeleteConfirmationHandler}/>}
        
        {showCheckAnimation && <CheckAnimation/>}
        {showFailAnimation && <FailAnimation/>}

        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    <div className={classes.main_title}>
                        {names.managewebsites} 
                        {checkedNumber>0 ? (<div className={classes.items_selected_div}>{checkedNumber} {names.item_selected}</div>):(<div className={classes.items_selected_div}></div>)}
                    </div>

                    {showWebsitesDiv?(
                        <>
                            <div className={classes.main_search}>
                                <CustomSelect value={queryField} onChange={(e) => setQueryField(e.target.value)}>
                                    <option value='domain'>{creative_table_headers.domain}</option>
                                    <option value='post_id'>{creative_table_headers.post_id}</option>
                                    <option value='status'>{creative_table_headers.status}</option>
                                    <option value='scheduled_date'>{creative_table_headers.scheduled_date}</option>
                                    <option value='schedule_on'>{creative_table_headers.schedule_on}</option>
                                    <option value='target_websites'>{creative_table_headers.target_websites}</option>
                                    <option value='title'>{creative_table_headers.title}</option>
                                    <option value='link'>{creative_table_headers.link}</option>
                                </CustomSelect>
                                <SearchBar placeholder='Search' onChange={handleSearchChange}/>
                            </div>

                            <div className={classes.main_operation}>
                                <div className={classes.customButton}><CustomButton color='purple' name={names.bodytag} id='body' onClick={toggleClipboardDiv}/></div>
                                <div className={classes.customButton}><CustomButton color='red' name={names.downloadpushjs} id='push' onClick={toggleClipboardDiv}/></div>
                                <div className={classes.customButton}><CustomButton color='orange' name={names.downloadswjs} id='sw' onClick={toggleClipboardDiv}/></div>

                                <div className={classes.customButton}><CustomButton color='blue' name={names.checkClickData} onClick={importWebsiteClicksData}/></div>
                                <div className={classes.customButton}><CustomButton color='green' name={names.addWebsites} type='submit' onClick={handleAddNewWebsite} disabled={checkedNumber>=1}/></div>
                                <div className={classes.customButton}><CustomButton color='red' name={names.delete} type='submit' onClick={handleBulkDelete} disabled={checkedNumber===0}/></div>
                            </div>
                        </>
                    ):(
                        <>
                        <div className={classes.main_search}>
                            <label>{names_from_suscribers.start_date}</label>
                            <input className={classes.input_date} type='date' onChange={handleChangeStartDate}/>
                            <label>{names_from_suscribers.end_date}</label>
                            <input className={classes.input_date} type='date' onChange={handleChangeEndDate}/>
                            <div className={classes.customButton}><CustomButton color='green' name={names_from_suscribers.search_data} onClick={handleSearchByDate}/></div>
                        </div>


                        <div className={classes.main_operation}>
                            <div className={classes.customButton}><CustomButton color='orange' reverse name={names.showWebsites} onClick={handleShowWebsiteDiv}/></div>
                        </div>
                        </>
                    )}
                </div>

                <ResponsiveTable loadingResults={loadingResults}>
                    {showWebsitesDiv?(
                        <>
                            <thead><HeadersAllWebsites headernames={website_table_headers} handleSelectAll={handleSelectAll} isCheckAll={isCheckAll}/></thead>
                            <tbody className={classes.table_data_website}>
                                {(Data.map((item, index) =>  <WebsiteControlTableItem data={item} 
                                                                index={index} 
                                                                isCheckList={isCheck} 
                                                                handleClick={handleClick}
                                                                handleEditWebsite={handleEditWebsite} 
                                                                handleDeleteWebsite={handleDeleteEachWebsite}
                                                                handleScheduleNow={handleScheduleNow}
                                                                handleVerifyNow={handleVerifyNow}
                                                                />))}
                                {Data.length == 0 && <div className={classes.no_data_found_div}>{names.no_data_found_website}</div>} 
                            </tbody>
                        </>
                    ):(
                        <>
                            <thead><HeadersAllWebsitesClicks showByQueryDiv={showByQueryDiv} headernames={click_headers}/></thead>
                            <tbody className={classes.table_data}>
                                {(ClickData.map((item, index) =>  <WebsiteControlTableItemClicks showByQueryDiv={showByQueryDiv} data={item}/>))}
                                {Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_clicks_data_found}</div>} 
                            </tbody>
                        </> 
                    )}
                </ResponsiveTable>
                  
                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResultsAfterQuery={numberOfResultsAfterQuery} numberOfResults={numberOfResults} numberOfPages={numberOfPages} totalEntries={numberOfTotalEntries} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    <div>
                        {showScheduleNowMsg && <div>Website is scheduled : {scheduleNowDomain}</div>}
                        {showScheduleNowErrorMsg && <div className={classes.formAlertDiv}>Error in website instant schedule : {scheduleNowDomain}. Please try again later</div>}
                        {showDownloadSwSuccess && <div>Downloading sw.js</div>}
                        {showDownloadSwError && <div  className={classes.formAlertDiv}>Error in download sw.js</div>}
                        {showVerifyNowMsg && <div>Website is verifying : {verifyNowDomain}</div>}
                        {showVerifyNowErrorMsg && <div className={classes.formAlertDiv}>Error in website instant verification : {verifyNowDomain}. Please try again later</div>}
                        {showScheduleToggleMsg && <div>Schedule on/off changed</div>}
                        {showScheduleToggleMsgError && <div className={classes.formAlertDiv}>Error in changing scheduele on/off{verifyNowDomain}. Please try again later</div>}
                        {showDeleteErrorAlert && <div className={classes.formAlertDiv}>Error occurred in deleting websites. Please try again later</div>}
                        {showAddWebsiteMsg && <div>Successfully created websites with {createdDomains}</div>}
                        {showsetNotCreatedDomainsError && <div className={classes.formAlertDiv}>Following domains already exists or invalid: {notCreatedDomains}. Therefore, not created any website with these domains</div>}
                        {showDateError && <div className={classes.formAlertDiv}>Start or End Date can't be empty. Please check date values</div>}

                    </div>
                    {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
    </>
    )
}
export default WebsiteControl;





