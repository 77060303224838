import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import classes from '../Creatives.module.scss';
import edit_classes from '../../AddNewCreative/AddNewCreative.module.scss';


import PopUpCard from '../../Cards/PopUpCard/PopUpCard'
import EditImageHeaders from '../EditImageTable/EditImageHeaders'
import EditImageTableItem from '../EditImageTable/EditImageTableItem'
import ResponsiveTable from '../../Customs/ResponsiveTable/ResponsiveTable'
import CustomButton from '../../Customs/CustomButton/CustomButton';
import CheckAnimation from '../../CheckAnimation/CheckAnimation';
import FailAnimation from '../../FailAnimation/FailAnimation';
import LoadingTable from '../../LoadingTable/LoadingTable';

import { TimeOutHandler } from '../../../utils/helper_functions';

import copy_green from '../../../assets/icons/copy_green.svg'
import copy_gray from '../../../assets/icons/copy_gray.svg'



const EditImage = (props) => {
    const target_creative = props.targetEditImageCreative
    const editImagesHeaders = props.editImagesHeaders
    const setForceGetCreatives = props.setForceGetCreatives

    const imagesData = props.imagesData 
    const setImagesData = props.setImagesData 

    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)
    const names = useSelector(state => state.ui.language.values.creativesContent)
    const headers_names = useSelector(state => state.ui.language.values.creativesContent.edit_image_headers)

    const [targetTitle, setTargetTitle] = useState('')
    const [targetDescription, setTargetDescription] = useState('')
    const [targetLink, setTargetLink] = useState('')
    const [targetDate, setTargetDate] = useState('')
    const [targetImagePath, setTargetImagePath] = useState('')
    const [showAddImagesDiv, setShowAddImagesDiv] = useState(false) 

    const [targetImage, setTargetImage] = useState()
    const [showEditImageDiv, setShowEditImageDiv] = useState(false) 

    // upload image states
    const [imageLinks, setImageLinks] = useState('')
    const [imageFile, setImageFile] = useState([])

    // duplicate image
    const [showDuplicateFailed, setShowDuplicateFailed] = useState(false) 

    // failed msg state
    const [showFailedMsg, setShowFailedMsg] = useState(false)

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)

    // animation section
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)

    // copy text green/gray icon state
    const [showGreenIcon, setShowGreenIcon] = useState(false)
    const [type, setType] = useState('')

    // charater left state
    const [titleLeft, setTitleLeft] = useState(256)
    const [desLeft, setDesLeft] = useState(512)
    const [imgLeft, setImgLeft] = useState(256)
    const [linkLeft, setLinkLeft] = useState(256)


    // calculate character left 
    useEffect(()=>{
        setTitleLeft(256-targetTitle.length)
    }, [targetTitle])
    useEffect(()=>{
        setDesLeft(512-targetDescription.length)
    }, [targetDescription])
    useEffect(()=>{
        setLinkLeft(256-targetLink.length)
    }, [targetLink])
    useEffect(()=>{
        setImgLeft(256-imageLinks.length)
    }, [imageLinks])

    
    const animation_function = (status_code) =>{
        if(status_code === 200){
            setShowCheckAnimation(true)
        }else{
            setShowFailAnimation(true)
        }
        TimeOutHandler(() => {
            setShowCheckAnimation(false)
            setShowFailAnimation(false)
        }, 1500)
    }


    const go_back_to_initial_state = () => {
        setTargetTitle('')
        setTargetDescription('')
        setTargetLink('')
        setTargetDate('')
        setImageLinks('')
        setImageFile([])

    }

    // ############################ get all images of each target creative section ############################
    const get_images_for_creative = async (id, domain) => {
        if(imagesData.length === 1){
            setForceGetCreatives(true)
        }

        setLoadingResults(true)
        const response = await fetch(`${API_URL}/api/v1/getcreativeimages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
               id:id,
               domain:domain
            })
        })
        const res_status = response.status
        const data = await response.json()
       
        if(res_status === 200) {
            setImagesData(data.res_images)
        }
        setLoadingResults(false)
    }

    useEffect(() => {
        get_images_for_creative(target_creative.id, target_creative.domain)
    }, [])



    // ############################ image upload section ############################
    const toggleShowAddImagesDiv = () => {
        setShowAddImagesDiv(!showAddImagesDiv)
    }

    const hanldeUploadImages = async () => {
        setLoadingResults(true)

        const upload_data = JSON.stringify({
            'creative':target_creative.id,
            'domain':target_creative.domain,
            'image_links':imageLinks
        })

        let formdata = new FormData()
        formdata.append("data", upload_data)
        for (let i = 0; i < imageFile.length; i++) {
            formdata.append(imageFile[i].name, imageFile[i])
        }
        
        const upload_res = await fetch(`${API_URL}/api/v1/creativeimages`, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${api_token}`},
            body:formdata,
        })

        const res_status = upload_res.status
        const data = await upload_res.json()

        if(res_status === 200){
            if(data.today_included){
                setForceGetCreatives(true)
            }
        }else{
            setShowFailedMsg(true)
            TimeOutHandler(()=>{
                setShowFailedMsg(false)
            }, 4000)
        }
    
        animation_function(res_status)
        setShowAddImagesDiv(false)
        get_images_for_creative(target_creative.id, target_creative.domain)

    }


    // ############################ image edit section ############################
    const toggleEditImagesDiv = (e) =>{
        if(!showEditImageDiv){
            setTargetImage(imagesData[e.target.id])
            setTargetImagePath(imagesData[e.target.id]['file_path'])
        }else{
            go_back_to_initial_state()
        }
        setShowEditImageDiv(!showEditImageDiv)
    }


    // full edit 
    const handleSaveCreativeImage = () =>{
        update_data_for_image()
    }

    // section for edit date only
    const handle_date_change = (e) => {
        setTargetDate(e.target.value)
        setTargetImagePath(imagesData[e.target.id]['file_path'])
    }

    const update_data_for_image = async() => {
        setLoadingResults(true)

        const upload_data = JSON.stringify({
            'creative_id':target_creative.id,
            'domain':target_creative.domain,
            'target_title':targetTitle,
            'target_description':targetDescription,
            'target_link':targetLink,
            'target_image_path':targetImagePath,
            'target_date':targetDate,
            'image_links':imageLinks
        })

        let formdata = new FormData()
        formdata.append("data", upload_data)
        for (let i = 0; i < imageFile.length; i++) {
            formdata.append('file', imageFile[i])
        }

        const response = await fetch(`${API_URL}/api/v1/updateimages`, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${api_token}`},
            body:formdata
        })

        setLoadingResults(false)
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            setImagesData(data.res_images)
            go_back_to_initial_state()
            if (targetDate !=="" || data.today === targetImage['when']){
                setForceGetCreatives(true)
            }
        }else{
            setShowFailedMsg(true)
            TimeOutHandler(()=>{
                setShowFailedMsg(false)
            }, 4000)
        }
        animation_function(res_status)
        if(res_status===200){
            setShowEditImageDiv(false)
        }
    }


    useEffect(()=>{
        if(targetDate!=='' && targetImagePath!=='' && !showEditImageDiv){
            update_data_for_image()
        }
    }, [targetDate])



    // ############################ image delete section ############################
    const handleDeleteImage = async (e) =>{
        setLoadingResults(true)
        const target_image = imagesData[e.target.id]

        const response = await fetch(`${API_URL}/api/v1/creativeimages`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`
            },
            body: JSON.stringify({
                creative_id:target_creative.id,
                domain:target_creative.domain,
                target_image_path: target_image.file_path,
            })
        })
        setLoadingResults(false)
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200){
            let temp_data = imagesData.filter((each_obj) => each_obj['file_path'] !== target_image['file_path'])
            setImagesData(temp_data)

            if(temp_data.length === 1 || data.today === target_image['when']){
                setForceGetCreatives(true)
            }
        }
        animation_function(res_status)
    }


    // ############################ image duplicate section ############################
    const handleDuplicateImage = async (e) =>{
        setLoadingResults(true)
        const response = await fetch(`${API_URL}/api/v1/duplicateimage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                creative_id:target_creative.id,
                domain:target_creative.domain,
                target_image_path:imagesData[e.target.id]['file_path'],
            })
        })
        setLoadingResults(false)
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            if(data.updated){
                setImagesData(data.res_images)
            }
        }else{
            setShowFailedMsg(true)
            setShowDuplicateFailed(true)
            TimeOutHandler(()=>{
                setShowFailedMsg(false)
                setShowDuplicateFailed(false)
            }, 4000)
        }
        animation_function(res_status)        
    }



    // reset values
    const handleReset = () =>{
        setForceGetCreatives(false)
        go_back_to_initial_state()
    }

    return (
        <> 
            <PopUpCard toggle={props.toggleEditImage} header={`${showAddImagesDiv?'↑ Upload : ': `${showEditImageDiv?'✒︎ Edit : ':'→ Images :'}`} ${target_creative.title.substring(0, 100)}`}>
                {showCheckAnimation && <CheckAnimation/>}
                {showFailAnimation && <FailAnimation/>}
                {(showAddImagesDiv || showEditImageDiv) && loadingResults && <LoadingTable />}

                <div className={classes.fixed_height_div}>
                    {/* edit images section */}
                    {showEditImageDiv && (
                        <>
                        <div className={classes.upload_div}> 
                            <div className={edit_classes.creative_info_div}>
                                <div className={edit_classes.creative_info_div_items_without_margin}>
                                    <div className={edit_classes.creative_info_div_items_left}>
                                        <p className={edit_classes.flex_row_start_center}><b>● Title : </b>{targetImage.title}<img className={edit_classes.padding_row} src={showGreenIcon && type==='title'?copy_green:copy_gray} onClick={()=>{setTargetTitle(targetImage.title)}} onMouseEnter={() => {setShowGreenIcon(true); setType('title')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                        <p className={edit_classes.flex_row_start_start}>
                                            <div>
                                                <p><b>● Description : </b>{targetImage.description}</p>
                                            </div>
                                            <div>
                                                <img className={edit_classes.padding_row} src={showGreenIcon && type==='des'?copy_green:copy_gray} onClick={()=>{setTargetDescription(targetImage.description)}} onMouseEnter={() => {setShowGreenIcon(true); setType('des')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/>
                                            </div>
                                        </p>
                                        <p className={edit_classes.flex_row_start_center}><b>● Link : </b> <a target="_blank" rel="noreferrer" href={targetImage.link}>{targetImage.link}</a><img className={edit_classes.padding_row} src={showGreenIcon && type==='link'?copy_green:copy_gray} onClick={()=>{setTargetLink(targetImage.link)}} onMouseEnter={() => {setShowGreenIcon(true); setType('link')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}}  alt='copy'/></p>
                                        <p className={edit_classes.flex_row_start_center}><b>● File Name : </b>{targetImage.file_name}</p>
                                        <p className={edit_classes.flex_row_start_center}><b>● Date : </b>{targetImage.when}</p>
                                    </div>
                                    <div className={edit_classes.creative_info_div_items_right}>
                                        <div className={edit_classes.creative_info_div_items_right_right}>
                                            <p className={edit_classes.flex_row_start_center_img}><b>● Image</b><img className={edit_classes.padding_row} src={showGreenIcon && type==='img'?copy_green:copy_gray} onClick={()=>{setImageLinks(targetImage.image)}} onMouseEnter={() => {setShowGreenIcon(true); setType('img')}} onMouseLeave={() => {setShowGreenIcon(false); setType('')}} alt='copy'/></p>
                                            <a target="_blank" rel="noreferrer" href={targetImage.image}><img className={edit_classes.creative_info_div_items_right_image} src={targetImage.image} alt='edit_image'/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.each_edit_div_column}>
                                <label className={classes.upload_label}>{headers_names.title} {(titleLeft < 256 && titleLeft>0) ? <small>{`(${titleLeft} characters left)`}</small>:<small className={classes.red_text}>{targetTitle===''?'':'(limit exceed)'}</small>}</label>
                                <input className={classes.each_edit_input} placeholder="" value={targetTitle} onChange={(e)=>setTargetTitle(e.target.value)}/>
                            </div>
                            <div className={classes.each_upload_div}>
                                <label className={classes.upload_label}>{headers_names.description} {(desLeft < 512 && desLeft>0) ? <small>{`(${desLeft} characters left)`}</small>:<small className={classes.red_text}>{targetDescription===''?'':'(limit exceed)'}</small>}</label>
                                <textarea className={classes.upload_input_des} placeholder="" value={targetDescription} onChange={(e)=>setTargetDescription(e.target.value)}/>
                            </div>
                            <div className={classes.each_edit_div}>
                                <div className={classes.each_edit_div_left}>
                                    <label className={classes.upload_label}>{headers_names.link} {(linkLeft < 256 && linkLeft>0) ? <small>{`(${linkLeft} characters left)`}</small>:<small className={classes.red_text}>{targetLink===''?'':'(limit exceed)'}</small>}</label>
                                    <input className={classes.each_edit_input} placeholder="" value={targetLink} onChange={(e)=>setTargetLink(e.target.value)}/>
                                </div>
                                <div className={classes.each_edit_div_right}>
                                    <label className={classes.upload_label}>{headers_names.date}</label>
                                    <div className={`${edit_classes.middle_item_input_white}`}>
                                        <input type='date' value={targetDate!==""?targetDate:targetImage.when} onChange={(e)=>setTargetDate(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.each_edit_div}>
                                <div className={classes.each_edit_div_left}>
                                    <label className={classes.upload_label}>{names.upload_single_url} {(imgLeft < 256 && imgLeft>0) ? <small>{`(${imgLeft} characters left)`}</small>:<small className={classes.red_text}>{imageLinks===''?'':'(limit exceed)'}</small>}</label>
                                    {imageFile.length === 0?<textarea className={`${edit_classes.middle_item_input_white} ${classes.upload_input_short}`} placeholder='' value={imageLinks} onChange={(e)=>setImageLinks(e.target.value)}/>:<textarea disabled className={`${classes.middle_item_input_white} ${classes.upload_input_short}`} placeholder='disabled'/>}
                                </div>
                                <div className={classes.each_edit_div_right}>
                                    <label className={classes.upload_label}>{names.select_single_file}</label>
                                    <div className={`${edit_classes.middle_item_input_white} ${classes.upload_input_short}`}>
                                    {imageFile.length>0?<input type="file" accept="image/*" onChange={(e)=>setImageFile(e.target.files)}/>:<>{imageLinks===""? <input type="file" accept="image/*" value='' onChange={(e)=>setImageFile(e.target.files)}/>:<input disabled type="file" accept="image/*"/>}</>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )}

                    {/* upload images section */}
                    {showAddImagesDiv && (
                        <div className={classes.upload_div}>
                            <div className={classes.each_upload_div}>
                                <label className={classes.upload_label}>{names.upload_by_urls}</label>
                                <textarea className={classes.upload_input} placeholder={names.upload_by_urls_placeholder} onChange={(e)=>setImageLinks(e.target.value)}/>
                            </div>
                            <div className={classes.each_upload_div}>
                                <label className={classes.upload_label}>{names.select_files}</label>
                                <div className={classes.upload_input}>
                                    <input type="file" accept="image/*" multiple onChange={(e)=>setImageFile(e.target.files)}/>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    {/* images table section */}
                    {!showEditImageDiv && !showAddImagesDiv && (
                        <div className={classes.upload_div}>
                            <ResponsiveTable loadingResults={loadingResults}>
                                <thead><EditImageHeaders headernames={editImagesHeaders}/></thead>
                                <tbody className={classes.table_data}>
                                    {(imagesData.map((item, index) => <EditImageTableItem 
                                                                        data={item} 
                                                                        index={index}
                                                                        handle_date_change={handle_date_change} 
                                                                        toggleEditImagesDiv={toggleEditImagesDiv} 
                                                                        handleDeleteImage={handleDeleteImage}
                                                                        handleDuplicateImage={handleDuplicateImage}
                                                                        />))}
                                    {!loadingResults && imagesData.length === 0 && <div className={classes.no_data_found_div}>{names.no_clicks_data_found}</div>} 
                                </tbody>
                            </ResponsiveTable>
                        </div>
                    )}

                    <div className={classes.add_images_btn_div}>
                        <div className={classes.add_images_btn_div_each}>
                            {showEditImageDiv && <CustomButton color='red' name={names.reset} onClick={handleReset}/>}
                            
                            {/* error msg section */}
                            {showFailedMsg}{showEditImageDiv}
                            {showFailedMsg && 
                            <div className={classes.formAlertDiv}>
                                {showEditImageDiv && <div className={classes.alert_div}>Edit image failed</div>}
                                {showAddImagesDiv && <div className={classes.alert_div}>Upload images failed</div>}
                                {showDuplicateFailed && <div className={classes.alert_div}>Image duplication failed</div>}
                            </div>}
                        </div>

                        <div className={classes.add_images_btn_div_each}>
                            {/* button section */}
                            <div className={classes.customButton}>
                                {(showAddImagesDiv || showEditImageDiv) && 
                                <>
                                    {loadingResults? (<CustomButton disabled color='blue' name={names.go_back}/>):(<CustomButton color='blue' name={names.go_back} onClick={showAddImagesDiv?toggleShowAddImagesDiv:toggleEditImagesDiv}/>)}
                                </>
                                }
                            </div>
                            <div className={classes.customButton}>
                            {showEditImageDiv?(
                                <CustomButton color='green' name={names.save} onClick={handleSaveCreativeImage}/>
                            ):(
                                <>
                                    {!loadingResults ? (
                                        <CustomButton color='green' name={showAddImagesDiv?`${names.upload} ↑`:names.add_images} onClick={showAddImagesDiv?hanldeUploadImages:toggleShowAddImagesDiv}/>
                                    ):(
                                        <CustomButton disabled color='blue' name={showAddImagesDiv?names.upload:names.add_images}/>
                                    )}
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </PopUpCard>
        </>
    )
}

export default EditImage;