import classes from './Logs.module.scss'

import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';

import LogsHeaders from './LogsHeaders';
import LogsTableItem from './LogsTableItem/LogsTableItem';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


const Logs = () => {
    const names = useSelector(state => state.ui.language.values.logs)
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)

    const [Data, setData] = useState([])
    const API_URL = process.env.REACT_APP_API_URL


    useEffect( async () => { 
        setLoadingResults(true)
        await importLogs()
        setLoadingResults(false)
    },[pageNumber,numberOfResults])


    useEffect(() => {
        setPageNumber(1)
    },[numberOfResults])


    const importLogs = async () => {
        const response = await fetch(`${API_URL}/api/v1/logs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber,
                category: ''
            })
        })

        let res_status = response.status
        const data = await response.json()
        setLoadingResults(false)
        if(res_status === 200) {
            console.log("Response in get logs :", data, res_status)
            
            const total_entires = data.total_logs_count
            setNumberOfTotalEntries(total_entires)
            setData(data.res)
            setNumberOfResultsAfterQuery(data.logs_count)

            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }
        }else{
            console.log("Error in getting logs data")
        }
    }


    return (
        <>
        <div className={classes.container}>
            <div className={classes.main}>

                <div className={classes.main_top}>
                    <div className={classes.main_title}>{names.title}</div>
                    <div className={classes.main_middle}>
                    </div>
                </div>


                <ResponsiveTable loadingResults={loadingResults}>
                    <thead>
                        <LogsHeaders names={names.headers}/>
                    </thead>
                    <tbody className={classes.table_data}>
                        {Data.map((item,index) => <LogsTableItem data={item} key={index}/>)}
                    </tbody>
                </ResponsiveTable>

                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResultsAfterQuery={numberOfResultsAfterQuery} numberOfResults={numberOfResults} numberOfPages={numberOfPages} totalEntries={numberOfTotalEntries} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
        </>
    )
}

export default Logs;