import classes from '../CreativesUser/CreativesTableItem/CreativesTableItem.module.scss'

const CreativeHeaders = (props) => {
    const names = props.headernames
    const runDaily = props.runDaily
    
    return (
        <tr>
            <th><input id='selectAll' type="checkbox" name='selectAll' checked={props.isCheckAll} onChange={props.handleSelectAll}/><p>Select All</p></th>
            <th>{names.favicon}</th>
            <th>{names.domain}</th>
            <th>{names.post_id}</th>
            <th>{names.title}</th>
            <th>{names.description}</th>
            <th>{names.image}</th>
            <th>{names.link}</th>
            <th>{names.target_websites}</th>

            <th>{names.expected_subs}</th>

            <th>{names.run_daily}</th>
            <th>{names.automate}</th>
            <th>{names.already_ran_today}</th>
            {runDaily?(
                <th></th>
            ):(
                <th>{names.scheduled_date}</th>
            )}
            <th>{names.schedule_on}</th>
            <th>{names.status}</th>
            <th>{names.scheduled}</th>
            <th>{names.send_now}</th>
            <th>{names.screen_name}</th>
            <th>{names.u_id}</th>
            <th>{names.screen_started}</th>
            <th>{names.screen_ended}</th>
            <th>{names.created_at}</th>
            <th>{names.updated_at}</th>
            <th>{names.dispatched}</th>
            <th>{names.dispatch_failed}</th>
            <th>{names.impressions}</th>
            <th>{names.clicks}</th>
            <th>{names.actions}</th>
        </tr>
    )
}

export default CreativeHeaders;