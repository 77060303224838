import classes from './Login.module.scss'

import email_icon from '../../assets/icons/email.svg'
import email_red from '../../assets/icons/email_red.svg'
import email_blue from '../../assets/icons/email_blue.svg'
import email_green from '../../assets/icons/email_green.svg'
import lock from '../../assets/icons/lock.svg'
import lock_blue from '../../assets/icons/lock_blue.svg'
import lock_green from '../../assets/icons/lock_green.svg'

import CustomInput from '../Customs/CustomInput/CustomInput'
import CustomButton from '../Customs/CustomButton/CustomButton'

import { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { useDispatch } from 'react-redux'
import { userActions } from '../../store/user-slice'
import { uiActions } from '../..//store/ui-slice'

import { TimeOutHandler} from '../../utils/helper_functions'


const Login = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const [emailStatus, setEmailStatus] = useState('pending')
    const [passwordStatus, setPasswordStatus] = useState('pending')
    
    const [emailIcon, setEmailIcon] = useState(email_icon)
    const [passwordIcon, setPasswordIcon] = useState(lock)

    const dispatch = useDispatch()
    const navigate = useNavigate ()

    const API_URL = process.env.REACT_APP_API_URL

    let dashboard_allowed_list = [
        "mhnayem289@gmail.com",
        "info@icc.vision",
        "dima7667@gmail.com",
    ];


    const submitHandler = async (e) => {
        e.preventDefault()
        
        if(passwordStatus === 'success' && emailStatus === 'success') {
            props.setLoginProcess(true)
            const request_options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                    password: password,
                })
            }
            let response = await fetch(`${API_URL}/api/v1/login`, request_options)
            let res_code = response.status
            response = await response.json()

            // bad username/password
            if(res_code === 400) {
                setEmailStatus('error')
                setEmailIcon(email_red)
                props.setLoginProcess(false)
                return;
            }

            if(res_code === 200 && ['ADMIN','MODERATOR'].includes(response.role)) {
                if(!dashboard_allowed_list.includes(email)) {
                    props.setLoginProcess(false)
                    return;
                }

                let STEP_TWO_URL = `${API_URL}/api/v1/getcreativesbyquery`
                let step_two = await fetch(STEP_TWO_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${response.access_token}`},
                    body: JSON.stringify({
                        query_field: '',
                        query_value: '',    
                    })
                })
                let pushData = await step_two.json()
                console.log('LOGIN : ==== push data is ======> ', pushData)
                localStorage.setItem('access_token', response.access_token)
                localStorage.setItem('role', response.role)

                dispatch(userActions.setUser(email))
                dispatch(userActions.setToken(response.access_token))
                dispatch(userActions.setIsAdmin(['ADMIN'].includes(response.role)))
                dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(response.role)))
                
                dispatch(uiActions.setIsLoggedIn(true))
                dispatch(uiActions.setShowAdminInterface(['ADMIN','MODERATOR'].includes(response.role)))
                navigate('/', {replace: true })
            }
            TimeOutHandler(() => props.setLoginProcess(false))
        }
    }



    const emailHandler = (e) => {
        let value = e.target.value
        const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/

        if(value.length === 0) {
            setEmailIcon(email_icon)
        }
        else if(EMAIL_REGEX.test(value)) { 
            setEmailStatus('success')
            setEmailIcon(email_green)
        }
        else { 
            setEmailStatus('pending')
            setEmailIcon(email_blue)
        }
            
        setEmail(e.target.value)
    }

    const passwordHandler = (e) => {
        let value = e.target.value

        if(value.length === 0) {
            setPasswordIcon(lock)
        }
        else if(value.length >= 4) { 
            setPasswordStatus('success')
            setPasswordIcon(lock_green)
        }
        else { 
            setPasswordStatus('pending') 
            setPasswordIcon(lock_blue)
        }
        setPassword(value)
    }


    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.top_container}>
                    <span className={classes.title}>Push Dashboard Login</span>
                    <span className={classes.subtitle}>Arcane Technologies limited</span>
                </div>

                <div className={classes.form_container}>
                    <form className={classes.form} onSubmit={submitHandler}>
                        <div className={classes.form_item}>
                            <label htmlFor='email'>Email</label>
                            <CustomInput image_src={emailIcon} placeholder='email@email.com' name='email' type='email' onChange={emailHandler} value={email} required status={emailStatus}/>
                        </div>
                        <div className={classes.form_item}>
                            <label htmlFor='password'>Password</label>
                            <CustomInput image_src={passwordIcon} placeholder='************' name='password' type='password' onChange={passwordHandler} value={password} required status={passwordStatus}/>
                        </div>
                        <div className={classes.form_bottom}>
                            <div>
                                <CustomButton name='Login Now' color='red' reverse type='submit'/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login