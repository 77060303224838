import classes from './AmountSelect.module.scss';
import CustomSelect from '../Customs/CustomSelect/CustomSelect';

import useWindowSize from '../../assets/hooks/useWindowSize'
import { useSelector } from 'react-redux';


const AmountSelect = (props) => {
    const {width} = useWindowSize()
    const names = useSelector(state => state.ui.language.values.websitesContent.pagination)
    
    return (
        <div className={classes.container}>
            {width > 500 && <span>{`${props.numberOfResultsAfterQuery} ${names.rows} ${names.out_of} ${props.totalEntries}, ${names.page} ${props.pageNumber} ${names.of} ${props.numberOfPages}`}</span>}
            <CustomSelect onChange={props.onChange} value={props.numberOfResults}>
                <option value={24}>24</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={250}>250</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
            </CustomSelect>
        </div>
    )
}

export default AmountSelect