import classes from '../Creatives/Creatives.module.scss';

import pencil from '../../assets/icons/pencil.svg'
import delete_red from '../../assets/icons/delete_red.svg'
import delete_gray from '../../assets/icons/delete_gray.svg'




const AutomationTableItem = (props) => {
    const data = props.data
    const index = props.index
    const isCheckList = props.isCheckList

    return (
        <tr>
            <td><input id={index} onChange={props.handleClick} type="checkbox" name={data.domain} checked={isCheckList.includes(index)}/></td>

            <td><a href={data.favicon} target='_blank' rel="noreferrer"><img className={classes.favicon_icon} src={data.favicon} alt=''/></a></td>

            <td>{data.domain}</td>
            <td>{data.title}</td>
            <td>{data.description.substring(0,100)}...</td>
            <td>
                {data.image !== "" || data.image !== "error"?(<a href={data.image} target='_blank' rel="noreferrer"><img className={classes.creative_image_section} src={data.image} alt={''} target='_blank'/></a>):(<></>)}
            </td> 

            <td><a href={data.link} target="_blank" rel="noreferrer">{data.link}</a></td>

            <td>{data.automation_time !== 0?(<>{data.automation_time} minute</>):(<>Not Speficied</>)}</td> 

            <td>
                <img className={classes.action_button_each} src={pencil} alt='Edit'  id={index} onClick={props.handleChangeAutomationTiming}/>
                {props.checkedNumber===0?(<img className={classes.action_button_each} src={delete_red} alt='Delete' id={index} onClick={props.handleDeleteFromAutomation}/>):(<img src={delete_gray} alt='Delete' id={index}/>)}
            </td>
        </tr>
    )
}

export default AutomationTableItem;