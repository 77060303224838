import classes from '../Creatives/Creatives.module.scss';

import logs_blue from '../../assets/icons/logs_blue.svg'
import pencil from '../../assets/icons/pencil.svg'
import delete_red from '../../assets/icons/delete_red.svg'
import delete_gray from '../../assets/icons/delete_gray.svg'


const FunnelTierTableItem = (props) => {
    const data = props.data
    const index = props.index
    const isCheckList = props.isCheckList

    return (
        <tr>
            <td><input id={index} onChange={props.handleClick} type="checkbox" name={data.domain} checked={isCheckList.includes(index)}/></td>

            <td>{data.tier_name}</td>
            <td>{data.target_websites.join(', ')}</td>
            <td>{data.target_websites_len}</td>
            <td>{data.target_automation_len}</td>
            <td>{data.created_at}</td>
            <td>{data.updated_at}</td>
            
            <td className={classes.action_tab_buttons}>
                <img className={classes.action_button_each} src={logs_blue} alt='Show Funnel Creatives'  id={index} onClick={props.showAutomationDivForTargetTier}/>
                <img className={classes.action_button_each} src={pencil} alt='Edit'  id={index} onClick={props.handleSetEditFunnelTier}/>
                {props.checkedNumber===0?(<img className={classes.action_button_each} src={delete_red} alt='Delete' id={index} onClick={props.handleDeleteFunnelTier}/>)
                :(<img src={delete_gray} alt='Delete' id={index}/>)}
            </td>
        </tr>
    )
}

export default FunnelTierTableItem;