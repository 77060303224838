import classes from '../Creatives/Creatives.module.scss';

import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';
import CustomButton from "../Customs/CustomButton/CustomButton";

import SubscribersHeaders from './SubscribersHeaders/SubscribersHeaders';
import SubscribersTableItem from './SubscribersTableItem/SubscribersTableItem';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


const SubscribersData = () => {
    const names = useSelector(state => state.ui.language.values.subscribers)
    const api_token = useSelector(state => state.user.token)

    const [loadingResults, setLoadingResults] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)

    const [Data, setData] = useState([])
    const API_URL = process.env.REACT_APP_API_URL

    const [byQuery, setByQuery] = useState(false)
    const [showByQueryTable, setShowByQueryTable] = useState(false)
    const [showDateError, setShowDateError] = useState(false)
    
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(async () => { 
        setLoadingResults(true)
        await importSubscribers()
        setLoadingResults(false)
    },[pageNumber, numberOfResults])

    useEffect(() => {
        setPageNumber(1)
    },[numberOfResults])

    const importSubscribers = async () => {
        setShowDateError(false)

        if(byQuery===true){
            if(startDate.length<=0 || endDate.length<=0){
                setShowDateError(true)
                return
            }
        }
        const response = await fetch(`${API_URL}/api/v1/subsdata`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber,
                byquery:byQuery,
                start_date:startDate,
                end_date:endDate
            })
        })

        let res_status = response.status
        const data = await response.json()
        setLoadingResults(false)
        if(res_status === 200) {
            const total_entires = data.total_entries
            setNumberOfTotalEntries(total_entires)
            setData(data.res)
            setNumberOfResultsAfterQuery(data.subs_data_count)
            
            // console.log("Response in get subsdata :", byQuery, total_entires, res_status, data.res)
            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }

            if(byQuery===true){
                setShowByQueryTable(true)
            }
        }else{
            console.log("Error in getting subs data")
            setShowDateError(true)
        }
    }


    const handleSearchByDate = async () => {
        setByQuery(true)
        setLoadingResults(true)
        await importSubscribers()
        setLoadingResults(false)
    }


    const handleChangeStartDate = (e) => {
        setStartDate(e.target.value)
        setByQuery(true)
        setShowDateError(false)

    }

    const handleChangeEndDate = (e) => {
        setEndDate(e.target.value)
        setByQuery(true)
        setShowDateError(false)
    }
    
    

return (
    <>  
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    <div className={classes.main_title}>
                        {names.title} 
                    </div>

                    <div className={classes.main_operation}>
                        <label>{names.start_date}</label>
                        <input className={classes.input_date} type='date' onChange={handleChangeStartDate}/>
                        <label>{names.end_date}</label>
                        <input className={classes.input_date} type='date' onChange={handleChangeEndDate}/>
                        <div className={classes.customButton}><CustomButton color='green' name={names.search_data} type='submit' onClick={handleSearchByDate}/></div>
                    </div>
                </div>

                

                <ResponsiveTable loadingResults={loadingResults}>
                    <thead><SubscribersHeaders names={names.headers} showByQueryTable={showByQueryTable}/></thead>
                    <tbody className={classes.table_data_website}>
                        {(Data.map((item, index) =>  
                            <SubscribersTableItem showByQueryTable={showByQueryTable} data={item}/>))}
                        {Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_data_found}</div>} 
                    </tbody>
                </ResponsiveTable>

                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResultsAfterQuery={numberOfResultsAfterQuery} numberOfResults={numberOfResults} numberOfPages={numberOfPages} totalEntries={numberOfTotalEntries} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    <div>{showDateError && <div className={classes.formAlertDiv}>Start or End Date can't be empty. Please check date values</div>}</div>
                    {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
    </>
    )
}

export default SubscribersData;