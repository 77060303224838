import classes from '../../Creatives/Creatives.module.scss';


const SubscribersTableItem = (props) => {
    var data = props.data
    var subs_data = props.data.subs
    const showByQueryTable = props.showByQueryTable
    var website_url = "https://"+data.domain

    return (
        <tr>
            <td>{data.w_id}</td>
            <td><a href={data.favicon} target='_blank' rel="noreferrer"><img className={classes.favicon_icon} src={data.favicon} alt={''}/></a></td>
            <td><a href={website_url} target='_blank' rel="noreferrer">{data.domain}</a></td>
           {showByQueryTable?(
                <td>{data.subs}</td>
            ):(
                <>
                    <td>{subs_data.today}</td>
                    <td>{subs_data.yesterday}</td>
                    <td>{subs_data.last_seven_days}</td>
                    <td>{subs_data.last_thirty_days}</td>
                    <td>{subs_data.this_month}</td>
                    <td>{subs_data.last_month}</td>
                </>
           )}
        </tr>
    )
}

export default SubscribersTableItem;