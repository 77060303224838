import classes from './Creatives.module.scss';

import CustomSelect from '../Customs/CustomSelect/CustomSelect'
import SearchBar from '../SearchBar/SearchBar';
import AmountSelect from '../AmountSelect/AmountSelect';
import Pagination from '../Pagination/Pagination';
import CustomButton from '../Customs/CustomButton/CustomButton';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';

import CreativeHeaders from './CreativeHeaders';
import CreativesTableItem from './CreativesTableItem';

import CreativeHeadersClicks from './CreativeHeadersClicks';
import CreativesTableItemClicks from './CreativesTableItemClicks';

import AddNewCreative from '../AddNewCreative/AddNewCreative'
import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation'
import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation'

// import delete_red from '../../assets/icons/delete_red.svg'
// import delete_gray from '../../assets/icons/delete_gray.svg'

// edit image component
import EditImage from './EditImage/EditImage';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { clearAllTimeouts, TimeOutHandler} from '../../utils/helper_functions';



const Creatives = () => {
    // required variables and names
    const names = useSelector(state => state.ui.language.values.creativesContent)
    const names_from_suscribers = useSelector(state => state.ui.language.values.subscribers)

    const creative_table_headers = names.table_headers
    const creative_click_headers = names.clicks_table_headers
    
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)

    // pagination section
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)

    // data store section
    const [Data, setData] = useState([])

    // add new creatives section
    const [showAddNewCreative, setShowAddNewCreative] = useState(false) 
    const [editCreative, setEditCreative] = useState(false) 
    const [targetEditCreative, setTargetEditCreative] = useState('') 

    const [runDailyChanged, setRunDailyChanged] = useState(false)

    // delete creatives
    const [showDeleteCreativeConfirmation, setShowDeleteCreativeConfirmation] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    
    const [bulkDelete, setBulkDelete] = useState(false)
    const [targetIdToDelete, setTargetIdToDelete] = useState(0)
    const [showDeleteErrorAlert, setShowDeleteErrorAlert] = useState(false)
    const [showNotDeletedMsg, setShowNotDeletedMsg] = useState(false)
    const [notDeletedCreatives, setNotDeletedCreatives] = useState([])

    
    // query for search query
    const [queryField, setQueryField] = useState('domain')
    const [queryValue, setQueryValue] = useState('')

    // select one or multiple or all
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [checkedNumber, setCheckedNumber] = useState(0)


    // send now creative sections
    const [showSendNowMsg, setShowSendNowMsg] = useState(false)
    const [showSendNowErrorMsg, setShowSendNowErrorMsg] = useState(false)
    
    
    // common usestate for create and edit creatives 
    const [runDaily, setRunDaily] = useState(false)
    const [automate, setAutomate] = useState(false)
    

    // show clicks data table
    const [byQuery, setByQuery] = useState(false)
    const [showCreativesDiv, setShowCreativesDiv] = useState(true)
    // const [showClicksDataDiv, setShowClicksDataDiv] = useState(false)
    const [ClickData, setClickData] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [showDateError, setShowDateError] = useState(false)
    const [showByQueryDiv, setShowByQueryDiv] = useState(false)
    
    
    // edit images popup
    const [showEditImageDiv, setShowEditImageDiv] = useState(false)
    const [targetEditImageCreative, setTargetEditImageCreative] = useState([])
    const [imagesData, setImagesData] = useState([])
    const [forceGetCreatives, setForceGetCreatives] = useState(false)



    const toggleEditImage = (e) =>{
        if(!showEditImageDiv){
            const { id } = e.target;
            setTargetEditImageCreative(Data[id])
        }else{
            setImagesData([])
        }
        setShowEditImageDiv(!showEditImageDiv)
    }


    
    // handle chekc and select one /all section
    useEffect(()=>{
        setCheckedNumber(isCheck.length)
    },[isCheck])

    const handleSelectAll = e => {       
        setIsCheckAll(!isCheckAll);
        setIsCheck(Data.map((item, index) => index));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        // console.log("handle click clicked in creatives is:", id, checked)

        setIsCheck([...isCheck, parseInt(id)]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
    };



    // get creatives using query or  without query section
    useEffect(() => {
        setQueryValue('');
    }, [queryField]);

    useEffect(async () => {
        if(queryValue.length === 0) {
            setLoadingResults(true)
            await getCreatives()
            setLoadingResults(false)
        }
        else {
            clearAllTimeouts()
            TimeOutHandler(async() => {
                setLoadingResults(true)
                await getCreatives()
                setLoadingResults(false)
            }, 100)
        }
    },[pageNumber, numberOfResults, queryValue])



    useEffect(async()=>{
        if(forceGetCreatives){
            setLoadingResults(true)
            await getCreatives()
            setLoadingResults(false)
            setForceGetCreatives(false)
        }
    }, [showEditImageDiv])

   
    const getCreatives = async () => {
        const response = await fetch(`${API_URL}/api/v1/getcreativesbyquery`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: pageNumber,
                items: numberOfResults,
                query_field: queryField,
                query_value: queryValue,
            })
        })
        
        const res_status = response.status
        const data = await response.json()
        // // console.log("getcreativesbyquery is ===>", res_status, data.res, data.entries, data.total_entires, typeof data.total_entires, numberOfResults)
       
        if(res_status === 200) {
            const total_entires = data.total_entires
            setNumberOfTotalEntries(total_entires)
            setData(data.res)
            setNumberOfResultsAfterQuery(data.entries)

            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }
            // // console.log("getcreativesbyquery again is ===>", total_entires, data.entries, numberOfResults, numberOfResults > total_entires, Math.ceil(total_entires/numberOfResults))
        }
    }
    

    const handleSearchChange = (e) =>{
        clearAllTimeouts()
        var val = e.target.value
        setQueryValue(val)
    }

    const handleDeleteEachCreative = (e) =>{
        const { id } = e.target;
        setTargetIdToDelete(parseInt(id))
        setShowDeleteCreativeConfirmation(true)
    }

    const handleBulkDelete = () =>{
        // console.log("Indexes to be deleted at bulk is :", isCheck, checkedNumber, bulkDelete)
        setShowDeleteCreativeConfirmation(true)
        setBulkDelete(true)
    }
    

    const closeDeleteConfirmationHandler = () => {
        setShowDeleteCreativeConfirmation(false)
    }

    const goBackToInitialDeleteState = () =>{
        setTargetIdToDelete('')   
        setBulkDelete(false)
        setIsCheckAll(false)
        setIsCheck([])
        setCheckedNumber(0)

        TimeOutHandler(async() => {
            setShowCheckAnimation(false)
            setShowFailAnimation(false)
            setLoadingResults(true)
            await getCreatives()
            setLoadingResults(false)
        }, 100)
    }

    const deleteTargetCreatives = async () => {
        setShowDeleteCreativeConfirmation(false)
        setLoadingResults(true)
        
        var deleteTargetArticlesData = []
        if(bulkDelete){
            for(let i=0;  i<isCheck.length; i++){
                var each_creative = Data[isCheck[i]]
                var eachCreativeToDelete = {
                    id:each_creative['id'],
                    website:each_creative['website'],
                    domain:each_creative['domain'],
                    post_id: each_creative['post_id'],
                    schedule_on: each_creative['schedule_on']
                }
                deleteTargetArticlesData.push(eachCreativeToDelete)
            }

        }else{
            var target_creative_to_delete = Data[targetIdToDelete]
            // console.log("target item is :", targetIdToDelete, typeof targetIdToDelete, target_creative_to_delete)

            var eachCreativeToDelete = {
                id:target_creative_to_delete['id'],
                website:target_creative_to_delete['website'],
                domain:target_creative_to_delete['domain'],
                post_id: target_creative_to_delete['post_id'],
                schedule_on: target_creative_to_delete['schedule_on']
            }
            deleteTargetArticlesData.push(eachCreativeToDelete)
        }

        const response = await fetch(`${API_URL}/api/v1/deletetargetcreatives`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                deleteTargetArticlesData: deleteTargetArticlesData,
            })
        })
        
        setLoadingResults(false)
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            setShowCheckAnimation(true)
            console.log("delete response is is ===>", data.msg, data.success, data.delete_count)
            goBackToInitialDeleteState()
            
            const notDeleted = data.not_deleted
            if(notDeleted.length > 0){
                setNotDeletedCreatives(notDeleted)
                setShowNotDeletedMsg(true)
                TimeOutHandler(()=>{
                    setShowNotDeletedMsg(false)
                    setNotDeletedCreatives([])
                }, 3000)
            }

        }
        else{
            console.log("=== Error in delete articles ===> ", res_status, data.success, data.error, data.delete_count)
            setShowDeleteErrorAlert(true)
            setShowFailAnimation(true)
            TimeOutHandler(()=>{
                setShowDeleteErrorAlert(false)
            }, 3000)
            goBackToInitialDeleteState()
        }
    }
   


    const handleAddNewCreative = () => {
        setShowAddNewCreative(true)
    }

    const handleEditCreative = (e) =>{
        const { id } = e.target;
        // // console.log('handleEditCreative :', id, Data[id]['run_daily'])
        setTargetEditCreative(Data[id])
        setEditCreative(true)
        setRunDaily(Data[id]['run_daily'])
        setAutomate(Data[id]['automate'])
        setShowAddNewCreative(true)
    }


    const handleSendNowCreative = async (e) =>{
        const {id} = e.target;
        var targetSendNowObj = Data[id]
        
        // console.log('handle creative send now id =====>:', id, targetSendNowObj, targetSendNowObj['domain'])
        const response = await fetch(`${API_URL}/api/v1/creativesendnow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                sendNowCreative:[targetSendNowObj],
            })
        })
        
        const res_status = response.status
        const data = await response.json()
        if(res_status === 200) {
            // console.log("send now response is ===>", data.send_now, data.not_allowed)
            setShowSendNowMsg(true)

            setLoadingResults(true)
            await getCreatives()
            setLoadingResults(false)
            
            TimeOutHandler(()=>{
                setShowSendNowMsg(false)
            }, 3000)
        }
        else{
            setShowSendNowErrorMsg(true)
            TimeOutHandler(()=>{
                setShowSendNowErrorMsg(false)
            }, 3000)
            console.log("=== Error in send now website ===> ", res_status, data.error)
        }
    }



    const toggleRunDaily = (e) =>{
        setRunDaily(e.target.checked)
        setRunDailyChanged(true)

    }


    const handleShowCreativeDiv = () =>{
        setShowCreativesDiv(true)
    }

    const importCreativeClicksData = async () =>{
        if(byQuery===true){
            if(startDate.length<=0 || endDate.length<=0){
                setShowDateError(true)
                return
            }
        }
        
        setShowCreativesDiv(false)
        setLoadingResults(true)
        const response = await fetch(`${API_URL}/api/v1/creativeclicks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                items: numberOfResults,
                page: pageNumber,
                byquery:byQuery,
                start_date:startDate,
                end_date:endDate
                })
            })
            
            const res_status = response.status
            const data = await response.json()
            // console.log("creative clicks data returned is ===>", res_status, data.res, data.clicks_data_count, data.total_entries, numberOfResults)
           
            if(res_status === 200) {
                const total_entires = data.total_entires
                setNumberOfTotalEntries(total_entires)
                setClickData(data.res)
                setNumberOfResultsAfterQuery(data.clicks_data_count)
    
                if(numberOfResults > total_entires){
                    setNumberOfPages(1)
                }else{
                    setNumberOfPages(Math.ceil(total_entires/numberOfResults))
                }
                
                if(byQuery===true){
                    setShowByQueryDiv(true)
                }else{
                    setShowByQueryDiv(false)
                    setStartDate('')
                    setEndDate('')
                }
            }
            setLoadingResults(false)
        }


    const handleChangeStartDate = (e) =>{
        setStartDate(e.target.value)
        setByQuery(true)
        setShowDateError(false)
    }

    const handleChangeEndDate = (e) =>{
        setEndDate(e.target.value)
        setByQuery(true)
        setShowDateError(false)
    }

    const handleSearchByDate = async (e) => {
        setByQuery(true)
        setLoadingResults(true)
        await importCreativeClicksData()
        setLoadingResults(false)
    }

    const handleShowClicksAgain = async (e) => {
        setByQuery(false)
        setLoadingResults(true)
        await importCreativeClicksData()
        setLoadingResults(false)
    }



    return (
    <>  
        {showEditImageDiv && <EditImage setForceGetCreatives={setForceGetCreatives} toggleEditImage={toggleEditImage} targetEditImageCreative={targetEditImageCreative} imagesData={imagesData} setImagesData={setImagesData} editImagesHeaders={names.edit_image_headers}/>}
        {showAddNewCreative && <AddNewCreative setRunDaily={setRunDaily} runDaily={runDaily} runDailyChanged={runDailyChanged} setRunDailyChanged={setRunDailyChanged} automate={automate} setAutomate={setAutomate} toggleRunDaily={toggleRunDaily} getCreatives={getCreatives} setLoadingResults={setLoadingResults} setShowAddNewCreative={setShowAddNewCreative} setEditCreative={setEditCreative} editCreative={editCreative} targetEditCreative={targetEditCreative} setTargetEditCreative={setTargetEditCreative}/>}
        {showDeleteCreativeConfirmation && <DeleteConfirmation delete_title={names.delete_title} onYes={deleteTargetCreatives} onNo={closeDeleteConfirmationHandler}/>}
        {showCheckAnimation && <CheckAnimation/>}
        {showFailAnimation && <FailAnimation/>}

        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top}>
                    {showCreativesDiv?(
                        <div className={classes.main_title}>
                            {names.creatives}
                            {checkedNumber>0 ? (<div className={classes.items_selected_div}>{checkedNumber} {names.item_selected}</div>):(<div className={classes.items_selected_div}></div>)}
                        </div>
                    ):(
                        <div className={classes.main_title}>
                            {names.creatives_clicks}
                            {checkedNumber>0 ? (<div className={classes.items_selected_div}>{checkedNumber} {names.item_selected}</div>):(<div className={classes.items_selected_div}></div>)}
                        </div>
                    )}

                    {showCreativesDiv?(
                        <div className={classes.main_search}>
                            <CustomSelect value={queryField} onChange={(e) => setQueryField(e.target.value)}>
                                <option value='domain'>{creative_table_headers.domain}</option>
                                <option value='post_id'>{creative_table_headers.post_id}</option>
                                <option value='status'>{creative_table_headers.status}</option>
                                <option value='scheduled_date'>{creative_table_headers.scheduled_date}</option>
                                <option value='schedule_on'>{creative_table_headers.schedule_on}</option>
                                <option value='target_websites'>{creative_table_headers.target_websites}</option>
                                <option value='title'>{creative_table_headers.title}</option>
                                <option value='link'>{creative_table_headers.link}</option>
                            </CustomSelect>
                            <SearchBar placeholder='Search' onChange={handleSearchChange}/>
                        </div>
                    ):(
                        <div className={classes.main_operation}>
                        <label>{names_from_suscribers.start_date}</label>
                        <input className={classes.input_date} type='date' value={startDate} onChange={handleChangeStartDate}/>
                        <label>{names_from_suscribers.end_date}</label>
                        <input className={classes.input_date} type='date'  value={endDate} onChange={handleChangeEndDate}/>
                        <div className={classes.customButton}><CustomButton color='green' name={names_from_suscribers.search_data} onClick={handleSearchByDate}/></div>
                    </div>
                    )}

                    <div className={classes.main_operation}>
                        {showCreativesDiv?(
                            <>
                                <div className={classes.customButton}><CustomButton color='blue' name={names.checkClickData} onClick={importCreativeClicksData}/></div>
                                <div className={classes.customButton}><CustomButton color='green' name={names.addCreatives} type='submit' onClick={handleAddNewCreative} disabled={checkedNumber>=1}/></div>
                                <div className={classes.customButton}><CustomButton color='red' name={names.delete} type='submit' onClick={handleBulkDelete} disabled={checkedNumber===0}/></div>
                            </>
                        ):(
                            <>
                                <div className={classes.customButton}><CustomButton reverse color='orange' name={names.checkCreativeDiv} onClick={handleShowCreativeDiv}/></div>
                            </>
                        )}
                    </div>
                </div>

                <ResponsiveTable loadingResults={loadingResults}>
                    {showCreativesDiv?(
                        <>
                            <thead> <CreativeHeaders runDaily={runDaily} headernames={creative_table_headers} handleSelectAll={handleSelectAll} isCheckAll={isCheckAll}/></thead>
                            <tbody className={classes.table_data}>
                                {(Data.map((item, index) =>  <CreativesTableItem data={item} index={index} isCheckList={isCheck} handleClick={handleClick} handleEditCreative={handleEditCreative} toggleEditImage={toggleEditImage} handleDeleteCreative={handleDeleteEachCreative} handleSendNowCreative={handleSendNowCreative}/>))}
                                {Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_data_found}</div>} 
                            </tbody>
                        </>
                    ):(
                        <>
                            <thead><CreativeHeadersClicks showByQueryDiv={showByQueryDiv} headernames={creative_click_headers}/></thead>
                            <tbody className={classes.table_data}>
                                {(ClickData.map((item, index) =>  <CreativesTableItemClicks showByQueryDiv={showByQueryDiv} data={item}/>))}
                                {Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_clicks_data_found}</div>} 
                            </tbody>
                        </> 
                    )}
                </ResponsiveTable>
                  
                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResultsAfterQuery={numberOfResultsAfterQuery} numberOfResults={numberOfResults} numberOfPages={numberOfPages} totalEntries={numberOfTotalEntries} onChange={(e) => setNumberOfResults(e.target.value)}/>
                    {showDeleteErrorAlert && <div className={classes.formAlertDiv}>Error occurred in deleting creatives. Please try again later</div>}
                    {showNotDeletedMsg && <div className={classes.formAlertDiv}>Creatives deleted except few such as: {notDeletedCreatives}</div>}
                    
                    {showSendNowMsg && <div>Sending selected creatives</div>}
                    {showSendNowErrorMsg && <div className={classes.formAlertDiv}>Error occurred in sending selected creative</div>}
                    {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}

                    {showDateError && <div className={classes.formAlertDiv}>Start or End Date can't be empty. Please check date values</div>}
                </div>
            </div>
        </div>
    </>
    )
}

export default Creatives;