import classes from './DeleteConfirmation.module.scss'

import CustomButton from '../../Customs/CustomButton/CustomButton';

const DeleteConfirmation = (props) =>{
    return (
        <>
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.title}>{props.delete_title}</div>
                        <div className={classes.buttons}>
                            <div className={classes.each_button}>
                                <CustomButton name='No' color='blue' reverse onClick={props.onNo}/>
                            </div>
                            <div className={classes.each_button}>
                                <CustomButton name='Yes' color='red' reverse onClick={props.onYes}/>
                            </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default DeleteConfirmation;