import './App.css';
import Main from './components/Main/Main';
import Login from './components/Login/Login';
import LoginLoad from './components/LoginLoad/LoginLoad';

import {Route , Routes, Navigate } from 'react-router-dom'
import {useEffect, useState, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from './store/user-slice';
import { uiActions } from './store/ui-slice';
import { TimeOutHandler } from './utils/helper_functions';
import useWindowSize from './assets/hooks/useWindowSize.js'


function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate ()
  const initPath = useRef(window.location.pathname)
  const isLogged = useSelector(state => state.ui.isLoggedIn)
  const [loginProcess, setLoginProcess] = useState(false)
  const [show404, setShow404] = useState(false)

  const API_URL = process.env.REACT_APP_API_URL
  let {width} = useWindowSize()


  useEffect(() => {
      let token = localStorage.getItem('access_token')
      let role = localStorage.getItem('role')      
      
      if(token && role) {
         setLoginProcess(true)
         LoginHandler(token, role)
      }
  },[isLogged])


  useEffect(()=>{
    TimeOutHandler(() => {
      setShow404(true)
    }, 5000)
  }, [])


  const LoginHandler = (token, role) => {    
    if(['ADMIN','MODERATOR'].includes(role)){
        let REQ_PATH =`${API_URL}/api/v1/employees/data`
        fetch(REQ_PATH, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`},
        })
        .then(val => {
            // Case user edited the values in the localStorage
            var val_status = val.status

            if(val_status !== 200) {
              if(val_status === 400){
                  localStorage.removeItem('access_token')
                  localStorage.removeItem('role')
                }
                else if(val_status===500){
                  console.log("Internal app error. Contact admin :", val_status)
                }
                else{
                  console.log("Network or other issues occurred :", val_status)
                }

              console.log("Error in login using previous credentials. Try again")
              navigate('/login', {replace:true})
              setLoginProcess(false)
            }
            else{
              return val.json()
          }
        })
        .then(data => {
            var data = data['res']  
            dispatch(userActions.setUser(data['email']))
            dispatch(userActions.setIsAdmin(['ADMIN'].includes(data['role'])))
            dispatch(userActions.setIsModerator(['ADMIN','MODERATOR'].includes(data['role'])))
            dispatch(userActions.setToken(data['access_token']))
            dispatch(uiActions.setIsLoggedIn(true))
            
            if(!initPath.current.includes('login')) {
              navigate(initPath.current,{ replace: true })
              TimeOutHandler(() => {setLoginProcess(false)})
            }
            else {
              navigate('/',{ replace: true })
              TimeOutHandler(() => {setLoginProcess(false)})
            }
        })
    }
  }


  return (
    <div className="app">
      {loginProcess && <LoginLoad/>}
      {width < 500 ? <div>Please open the app in desktop</div>: 
        // will work on thsi section to create separate section for loggedin and not loggedin user
        <Routes>
          {isLogged?(
            <>
              <Route path='/:page' element={<Main />}/>
              <Route path='/' exact element={<Navigate to='/websites'/>}/>
            </>
          ):(
            <>
              <Route path='/' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
              <Route path='/login' exact element={<Login setLoginProcess={setLoginProcess}/>}/>
              <Route path='/:any' exact element={show404 && (
                  <div className='notFound'>
                    <p className='notFoundText'>404 Not Found</p>
                  </div>
                )
              }/>
            </>
          )}
        </Routes>
      }
    </div>
  )
}

export default App;
