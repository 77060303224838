import classes from '../AddNewCreative/AddNewCreative.module.scss'

import CustomButton from '../Customs/CustomButton/CustomButton'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

import {useSelector} from 'react-redux'
import { useEffect, useState } from 'react'
import { TimeOutHandler } from '../../utils/helper_functions';

import LoadingTable from '../LoadingTable/LoadingTable'



const EditAutomation = (props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    const targetEditAutomation = props.targetEditAutomation
    const names = useSelector(state => state.ui.language.values.automationContent)


    // all states
    const [newAutomationTime, setNewAutomationTime] = useState(0)
    const [loadingResults, setLoadingResults] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const [showFormAlert, setShowFormAlert] = useState(false)
    
    const [reloadAutomationTable, setReloadAutomationTable] = useState(false)


    useEffect(()=>{
        setNewAutomationTime(targetEditAutomation.automation_time)
    }, [])


    // function section
    const closeSetShowEditAutomation = () =>{
        props.setShowEditAutomation(false)

        props.setTargetEditAutomation('')
        setNewAutomationTime('')
        props.setLoadingResults(false)
    }



    useEffect(async()=>{    
        if(reloadAutomationTable){
            
            setLoadingResults(true)
            if(!props.showUnspecifiedDiv){
                await props.getAutomateCreative(props.targetFunnelTierId)
            }else{
                await props.getAutomateCreative('')
            }
            TimeOutHandler(()=>{
                setLoadingResults(false)
            }, 1200)
        }
    }, [reloadAutomationTable])



    const goBackToInitialTimeState = () =>{
        props.setTargetEditAutomation('')
        setReloadAutomationTable(false)
    }

    const handleEditAutomation = async () =>{
        // console.log("\n\n\nEdit with new value in automation clicked :", newAutomationTime, targetEditAutomation)
        setLoadingResults(true)

        var tier_id ;
        if(props.showUnspecifiedDiv){
            tier_id = ''
        }else{
            tier_id=props.targetFunnelTierId
        }

        const response = await fetch(`${API_URL}/api/v1/updateautomatedcreatives`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`
            },
            body: JSON.stringify({
                target_creative: targetEditAutomation.id,
                automation_time: newAutomationTime,
                tier_id:tier_id,
            })
        })

        const res_status = response.status
        const data = await response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            TimeOutHandler(()=>{
                setShowCheckAnimation(false)
                props.setShowEditAutomation(false)
            }, 1500)
            setReloadAutomationTable(true)
            goBackToInitialTimeState()
        }
        else{
            console.log("=== Error in update articles ===> ", res_status, data.success, data.error)
            setShowFormAlert(true)
            setShowFailAnimation(true)
            TimeOutHandler(()=>{
                setShowFormAlert(false)
            }, 4000)
            TimeOutHandler(()=>{
                setShowFailAnimation(false)
            }, 1500)
        }
        setLoadingResults(false)
    }


    const handleTimeChange = (e) => {
        const target_value = e.target.value
        if(target_value === '' || target_value<0){
            setNewAutomationTime(0)
        }else{
            setNewAutomationTime(parseInt(e.target.value))
        }
    }

    console.log('unspec :', targetEditAutomation)


    return (
        <PopUpCard toggle={closeSetShowEditAutomation} header={names.title_edit}>
            {showCheckAnimation && <CheckAnimation />}
            {showFailAnimation && <FailAnimation />}
            {loadingResults && <LoadingTable />}

           <div className={classes.automation_fixed_height_div_short}>            
                    <div className={classes.creative_info_div}>
                        <label className={classes.middle_item_label}>{names.editCreativeInfo}</label>
                        <div className={classes.creative_info_div_items_column}>
                            <p><b>Domain : </b>{targetEditAutomation.domain}</p>
                            <p><b>Title : </b>{targetEditAutomation.title}</p>
                            <p><b>Description : </b>{targetEditAutomation.description}...</p>
                            <p><b>Image : </b>{targetEditAutomation.image}</p>
                            <p><b>Link : </b><a target="_blank" rel="noreferrer" href={targetEditAutomation.link}>{targetEditAutomation.link}</a></p>
                            <p><b>Target Websites : </b><b>[</b>{targetEditAutomation.target_websites}<b>]</b></p>
                        </div>
                    
                        <form className={`${classes.main_middle} ${classes.border_top_solid}`}>
                            <div className={classes.middle_item}>
                                <div className={classes.middle_item_flex_column}>
                                    <label className={classes.middle_item_label_type_one}>{names.automation_time_label}</label>
                                    <div className={classes.middle_item_input}>
                                        <input type='number' min="1" placeholder={newAutomationTime} onChange={handleTimeChange}/>
                                    </div>
                                    <div>Run this automation at {newAutomationTime} minute once a user susbcribe</div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={classes.buttons}>
                        {showFormAlert && <div className={classes.formAlertDiv}>Already a creative exits with given automation time</div>}
                        <div className={classes.cancel} onClick={closeSetShowEditAutomation}>{names.cancel}</div>
                        <div className={classes.customButton}>
                            <CustomButton reverse color='red' name={names.btn_save} onClick={handleEditAutomation}/>
                        </div>
                    </div>
            </div>
        </PopUpCard>
    )
}


export default EditAutomation;