import classes from './NavBar.module.scss'

import NavBarItem from '../NavBarItem/NavBarItem'
import arrow from '../../assets/icons/double_arrow_left.svg'
import website_control from '../../assets/icons/website_control.svg'
import logs from '../../assets/icons/logs.svg'
import subscribers from '../../assets/icons/customers.svg'
import payments from '../../assets/icons/payments.svg'
import automation from '../../assets/icons/automation.svg'
import logout from '../../assets/icons/logout.svg'

import IconContainerMedium from '../Customs/IconContainerMedium/IconContainerMedium';

import { useSelector, useDispatch } from 'react-redux'
import { uiActions } from '../../store/ui-slice'
import { useEffect } from 'react'
import { useNavigate } from 'react-router';


const NavBar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const NAMES = useSelector(state => state.ui.language.values)
    const adminNames = NAMES.sideBarAdmin

    let isAdmin = useSelector(state => state.ui.showAdminInterface);
    let minimizeNavBar = useSelector(state => state.ui.minimizeNavBar)
    let width = window.screen.width

    useEffect(() => {
        if(width < 800 && width > 500) dispatch(uiActions.setMinimizeNavBar(true))

    })

    const minimizeToggle = () => {
        dispatch(uiActions.setMinimizeNavBar(!minimizeNavBar))
    }



    const logoutHandler = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('role')
        dispatch(uiActions.setIsLoggedIn(false))

        if(isAdmin) navigate('/login/',{ replace: true })
        else navigate('/login',{ replace: true })
    }

    return (
        <>
            <div className={!minimizeNavBar ? classes.container : classes.container_minimized}>

                <div className={classes.logo_container} onClick={(minimizeNavBar && width > 800) ? minimizeToggle : () => {}}>
                    {!minimizeNavBar && <div className={classes.logo}><p className={classes.logo_text}>Arcane</p></div>}
                    {!minimizeNavBar && <div className={classes.minimize} onClick={minimizeToggle}><img src={arrow} alt='<'/></div>}
                    {minimizeNavBar && <div className={classes.small_logo}><img src={arrow} alt='<'/></div>}
                </div>

                <div className={classes.navbar_container}>
                    <div className={classes.navbar_container_top}>
                        <NavBarItem image_src={website_control} image_alt={adminNames.websites} name={adminNames.websites} route='websites'/>
                        <NavBarItem image_src={payments} image_alt={adminNames.creatives} name={adminNames.creatives} route='creatives'/>
                        <NavBarItem image_src={automation} image_alt={adminNames.automation} name={adminNames.automation} route='automation'/>
                        <NavBarItem image_src={subscribers} image_alt={adminNames.subsData} name={adminNames.subsData} route='subscribers'/>
                        <NavBarItem image_src={logs} image_alt={adminNames.logs} name={adminNames.logs} route='logs'/>

                    </div>
                    <div className={classes.navbar_container_bottom}>
                        <div className={classes.logout} onClick={logoutHandler}>
                            <IconContainerMedium color='red' image_src={logout} alt='logout'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavBar;