const LogsHeaders = (props) => {
    const names = props.names

    return (
        <tr>
            <th>{names.responsible}</th>
            <th>{names.type}</th>
            <th>{names.date}</th>
            <th>{names.details}</th>
        </tr>
    )
}

export default LogsHeaders