import classes from '../../Creatives/Creatives.module.scss';

import delete_red from '../../../assets/icons/delete_red.svg'

import clock_blue from '../../../assets/icons/clock_blue.svg'
import informaton_red from '../../../assets/icons/information.svg'

import approved from '../../../assets/icons/approved.svg'
import disapproved from '../../../assets/icons/disapproved.svg'
import reset from '../../../assets/icons/reset.svg'




const WebsitesControlTableItem = (props) => {
    const data = props.data
    const index = props.index
    const isCheckList = props.isCheckList

    const website_url = "https://"+data.domain

    return (
        <tr>
            <td><input  id={index} onChange={props.handleClick} type="checkbox" name={data.domain.toString()} checked={isCheckList.includes(index)}/></td>
            <td>{data.w_id}</td>
            <td> <a href={data.favicon} target='_blank'> <img className={classes.favicon_icon} src={data.favicon}/> </a>
            </td>


            <td> <a href={website_url} target='_blank'>{data.domain}</a> </td>
            <td>{data.verified === true ?( <img src={approved} alt={data.verified.toString()}/>
                ):(<img src={disapproved} alt={data.verified.toString()}/>)}
            </td>
            <td>{data.verification_checked === true ? (<img src={approved} alt={data.verification_checked.toString()}/>
                ):(<img src={disapproved} alt={data.verification_checked.toString()}/>)}
            </td>

            <td>{data.verify_running === true? (<img  id={index} className={classes.action_button_each_progress} src={informaton_red} alt='Verify Running'/>
                ):(<img  id={index} className={classes.action_button_each} src={reset} alt='Verify Now' onClick={props.handleVerifyNow}/>)}                
            </td>
            
            <td>{data.total_subscribers}</td>
            <td>{data.total_unsubscribed}</td>
            <td>{data.total_articles}</td>
            <td>{data.creatives_created}</td>

            {/* <td>
                {data.schedule_on_allowed === true ?
                ( 
                    <img  id={index} className={classes.action_button_each} src={approved} alt={data.schedule_on_allowed.toString()} onClick={props.handleScheduleToggle}/>
                ):(
                    <img  id={index} className={classes.action_button_each} src={disapproved} alt={data.schedule_on_allowed.toString()} onClick={props.handleScheduleToggle}/>
                )}
            </td> */}

            <td>
                {(data.verified === true) ?(
                    <>
                        {data.schedule_running === true? 
                        (
                            <img  id={index} className={classes.action_button_each_progress} src={informaton_red} alt='Schedule Running'/>
                        ):(
                            <img  id={index} className={classes.action_button_each} src={clock_blue} alt='Schedule Now' onClick={props.handleScheduleNow}/>
                        )}
                    </>):(<></>)}
            </td>

            <td>{data.dispatched}</td>
            <td>{data.dispatch_failed}</td>
            <td>{data.impressions}</td>
            <td>{data.clicks}</td>
            <td>{data.ctr}</td>
            <td>
                {/* <img className={classes.action_button_each_website} src={pencil} alt='Edit' onClick={props.handleEditWebsite}/> */}
                <img className={classes.action_button_each_website} src={delete_red} alt='Delete'  id={index} onClick={props.handleDeleteWebsite}/>
            </td>
        </tr>
    )
    }
export default WebsitesControlTableItem


    // const [showEditAndSave, setShowEditAndSave] = useState(false) 
    // const [newStatus, setNewStatus] = useState(props.data.status) 

    // const [Note, setNote] = useState(props.data.note)
    // const api_token = useSelector(state => state.user.token)
    // const language = useSelector(state => state.ui.language.code)
    // const API_URL = process.env.REACT_APP_API_URL

    // const navigate = useNavigate()

    // const data = props.data
    // const added = fullYearParser(data.added*1000)
    // let type = 'Website'
    // let typeclass = classes.website_text
    // let customer = '---'

    // const changeStatusHandler = async (e) => {

    //     const value = e.target.value

    //     let response = await fetch(`${API_URL}/api/v2/employees/websites/status`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'authorization': `Bearer ${api_token}`},
    //         body: JSON.stringify({
    //             status: value,
    //             websiteID: data.websiteID,
    //             customerID: data.customers[0]?.customerID
    //         })
    //     })

    //     let res_status = response.status;
    //     response = response.json()

    //     if(res_status === 200) {
    //         setNewStatus(value)
    //     }
    //     else {
    //         if(data.customers.length === 0) {
    //             alert('This Website has no Customers')
    //         }
    //         else {
    //             alert('something went wrong')
    //         }
    //     }
        
    // }

    // if(data.type === 'PORTAL') {
    //     type = 'Portal'
    //     typeclass = classes.portal_text
    // }
    // if(data.customers[0]) customer = `${data.customers[0].firstName} ${data.customers[0].lastName}`

    // return (
    //     <tr onMouseLeave={() => setShowEditAndSave(false)} key={props.forKey}>
    //         <td>{data.earned.NIS}</td>
    //         <td>{data.earned.USD}</td>
    //         <td className={typeclass}>{type}</td>
    //         <td>{added}</td>
    //         <td>
    //             <CustomSelect value={newStatus} onChange={changeStatusHandler} disabled={data.type === 'PORTAL'}>
    //                 <option value={'CUSTOMER_ACTIVE'}>{statusTranslator('Customer Active', language)}</option>
    //                 <option value={'COMPANY_ACTIVE'}>{statusTranslator('Company Active', language)}</option>
    //                 <option value={'COMPANY_INACTIVE'}>{statusTranslator('Company Inactive', language)}</option>
    //                 <option value={'ON_PAUSE'}>{statusTranslator('On Pause', language)}</option>
    //                 <option value={'INACTIVE'}>{statusTranslator('Inactive', language)}</option>
    //                 <option value={'PAYMENT_ISSUE'}>{statusTranslator('Payment Issue', language)}</option>
    //                 <option value={'CANCELING'}>{statusTranslator('Canceling',language)}</option>
    //                 <option value={'BLACK_LIST'}>{statusTranslator('Black List', language)}</option>
    //             </CustomSelect>
    //         </td>
    //         <td>{data.retainer}</td>
    //         <td className={classes.website}>
    //             <div className={classes.website_top}>
    //                 <a href={`https://www.${data.domain}`} target='_blank' rel="noreferrer">{data.domain}</a>
    //                 <div className={classes.icons}>
    //                     <img src={papers} alt='copy' onClick={() => copyToClipboard(data.domain)}/>
    //                     <img src={pencil} alt='edit' onClick={() => setShowEditAndSave(val => !val)}/>
    //                     <img src={history} alt='history' onClick={props.HistoryHandler}/>
    //                     <img src={edit} alt='edit' onClick={props.SettingsHandler}/>
    //                 </div>
    //             </div>
    //             {Note && 
    //             <div className={classes.note}>
    //                 <p>{`${Note}`}</p>
    //             </div>                
    //             }
    //             {showEditAndSave && <WebsitesEditAndSave websiteID={data.websiteID} note={Note} setNote={setNote} close={() => setShowEditAndSave(false)}/>}
    //         </td>
    //         <td>
    //             <div style={{cursor: 'pointer'}} onClick={() => navigate(`/user/customers?name=${data.customers[0].lastName}`, { replace: true})}>
    //                 {`${customer}`}
    //             </div>
    //         </td>
    //     </tr>
    // )
