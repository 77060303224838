import classes from '../Creatives.module.scss';
import date_classes from '../../AddNewCreative/AddNewCreative.module.scss'


import duplicate_gray from '../../../assets/icons/duplicate_gray.svg'
import duplicate_green from '../../../assets/icons/duplicate_green.svg'
import pencil_gray from '../../../assets/icons/pencil_gray.svg'
import pencil_blue from '../../../assets/icons/pencil.svg'
import delete_red from '../../../assets/icons/delete_red.svg'
import delete_gray from '../../../assets/icons/delete_gray.svg'




const EditImageTableItem = (props) => {
    const index = props.index
    const data = props.data

    return (
        <tr>
            <td className={classes.custom_name_field}>{data.title}</td>
            <td>{data.description.substring(0, 100)}...</td>
            <td><a href={data.link} target='_blank' rel="noreferrer">{data.link}</a></td>

            <td className={classes.custom_name_field}>{data.file_name}</td>
            <td><a href={data.image} target='_blank' rel="noreferrer"><img src={data.image} alt=""/></a></td>
            <td>
                <div className={date_classes.middle_item_input}>
                    {data.type==='Default'?<>{data.when}</>:<input id={index} type='date' value={data.when} onChange={props.handle_date_change}/>}
                </div>
            </td>
            <td>
                <div>
                    {data.type==='Default'?(
                        <>
                            <img className={classes.action_button_each_inactive} src={duplicate_gray} alt='Duplicate' disabled/>
                            <img className={classes.action_button_each_inactive} src={pencil_gray} alt='Edit' disabled/>
                            <img className={classes.action_button_each_inactive} src={delete_gray} alt='Delete' disabled/>
                        </>
                    ):(
                        <>
                            <img className={classes.action_button_each} src={duplicate_green} alt='Duplicate' id={index} onClick={props.handleDuplicateImage}/>
                            <img className={classes.action_button_each} src={pencil_blue} alt='Edit' id={index} onClick={props.toggleEditImagesDiv}/>
                            <img className={classes.action_button_each} src={delete_red} alt='Delete' id={index} onClick={props.handleDeleteImage}/>
                        </>
                    )}
                </div>
            </td>
        </tr>
    )
}


export default EditImageTableItem