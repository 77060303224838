import classes from '../CreativesUser/CreativesTableItem/CreativesTableItem.module.scss'

const CreativeHeadersClicks = (props) => {
    const names = props.headernames
    const showByQueryDiv = props.showByQueryDiv
    
    return (
        <tr>
            {showByQueryDiv?(
                <>
                    <th>{names.domain}</th>
                    <th>{names.favicon}</th>
                    <th>{names.title}</th>
                    <th>{names.image}</th>
                    <th>{names.link}</th>
                    <th>{names.total_clicks}</th>
                </>
            ):(
                <>
                    <th>{names.domain}</th>
                    <th>{names.favicon}</th>
                    <th>{names.title}</th>
                    <th>{names.image}</th>
                    <th>{names.link}</th>

                    <th>{names.today}</th>
                    <th>{names.yesterday}</th>
                    <th>{names.last_seven_days}</th>
                    <th>{names.last_thirty_days}</th>
                    <th>{names.this_month}</th>
                    <th>{names.last_month}</th>
                    <th>{names.total_clicks}</th>
                    <th>{names.updated}</th>
                    <th>{names.u_id}</th>
                </>
            )}
        </tr>
    )
}

export default CreativeHeadersClicks;