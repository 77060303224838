import classes from './AddNewDomain.module.scss'

import globe from '../../assets/icons/globe_gray.svg'

import CustomButton from '../Customs/CustomButton/CustomButton'
import CheckAnimation from '../CheckAnimation/CheckAnimation'
import FailAnimation from '../FailAnimation/FailAnimation'
import PopUpCard from '../Cards/PopUpCard/PopUpCard'

import {useSelector} from 'react-redux'
import { useState } from 'react'
import { TimeOutHandler } from '../../utils/helper_functions';


const AddNewDomain = (props) => {
    const names = useSelector(state => state.ui.language.values.websitesContent.AddNewDomain)
    const api_token = useSelector(state => state.user.token)

    const [domainValue, setDomainValue] = useState('')
    const [faviconValue, setFaviconValue] = useState('')
    
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const API_URL = process.env.REACT_APP_API_URL

    const createdDomains = props.createdDomains
    const notCreatedDomains = props.notCreatedDomains



    const handleCreateNewWebsite = async (e) => {
        e.preventDefault()
        props.setLoadingResults(true)
        props.setShowAddWebsiteMsg(false)
        props.setShowsetNotCreatedDomainsError(false)

        var addWebsiteDataObj = {domain:domainValue, favicon:faviconValue}
        // console.log("Domains sending for creating website is :", addWebsiteDataObj)
        let response = await fetch(`${API_URL}/api/v1/managewebsites`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                addWebsiteData: addWebsiteDataObj
            })
        })

        let res_status = response.status
        const data = await response.json()
        console.log("Data returned in create new website is :", data)
        props.setLoadingResults(false)
        if(res_status === 200){            
            if(data['domains_already_exists'].length>0){
                props.setNotCreatedDomains(notCreatedDomains => [...notCreatedDomains, data['domains_already_exists']])
                props.setShowsetNotCreatedDomainsError(true)
            }
            if(data['domains_added'].length>0){
                props.setCreatedDomains(createdDomains => [...createdDomains, data['domains_added']])
                props.setShowAddWebsiteMsg(true)
            }
            
            setShowCheckAnimation(true)
            setTimeout(() => {
                setShowCheckAnimation(false)
                props.toggle(false)
            }, 1500);

            props.setLoadingResults(true)
            await props.getWebsites()
            TimeOutHandler(() => {
                props.setLoadingResults(false)
            }, 1500)

        }
        else {
            setShowFailAnimation(true)
            setTimeout(() => {
                setShowFailAnimation(false)
                props.toggle(false)
            }, 1500);
        }
    }


   

    return (
        <PopUpCard toggle={props.toggle} header={names.title}>
                {showCheckAnimation && <CheckAnimation />}
                {showFailAnimation && <FailAnimation />}
                <form className={classes.main_middle}>
                    <div className={classes.middle_item}>
                        <label className={classes.middle_item_label}>{names.domain_name}</label>
                        <div className={classes.middle_item_input}>
                            <img src={globe} alt='globe'/>
                            <input placeholder='Input each domain name' value={domainValue} onChange={(e) => setDomainValue(e.target.value)} required/>
                        </div>
                    </div>
                    <div className={classes.middle_item}>
                        <label className={classes.middle_item_label}>{names.favicon}</label>
                        <div className={classes.middle_item_input}>
                            <img src={globe} alt='favicon'/>
                            <input placeholder='Copy & paste favicon image link' value={faviconValue} onChange={(e) => setFaviconValue(e.target.value)}/>
                        </div>
                    </div>

                    <div className={classes.buttons}>
                        <div className={classes.cancel} onClick={props.toggle}>{names.cancel}</div>
                        <div className={classes.customButton}><CustomButton reverse color='red' name={names.add} onClick={handleCreateNewWebsite}/></div>
                    </div>
                </form>
        </PopUpCard>
    )
}

export default AddNewDomain