const SubscribersHeaders = (props) => {
    const header_names = props.names
    const showByQueryTable = props.showByQueryTable

    return (
        <tr>
            <th>{header_names.w_id}</th>
            <th>{header_names.favicon}</th>
            <th>{header_names.domain}</th>
            {showByQueryTable?(
                <th>{header_names.subs_by_query}</th>
            ):(
                <>
                    <th>{header_names.today}</th>
                    <th>{header_names.yesterday}</th>
                    <th>{header_names.last_seven_days}</th>
                    <th>{header_names.last_thirty_days}</th>
                    <th>{header_names.this_month}</th>
                    <th>{header_names.last_month}</th>
                </>
            )}
            
    </tr>
    )
}
export default SubscribersHeaders