// import classes from '../Creatives/CreativesUser/CreativesTableItem/CreativesTableItem.module.scss'

const AutomationHeaders = (props) => {
    const names = props.headernames
    
    return (
        <tr>
            <th><input id='selectAll' type="checkbox" name='selectAll' checked={props.isCheckAll} onChange={props.handleSelectAll}/><p>Select</p></th>
            <th>{names.favicon}</th>
            <th>{names.domain}</th>
            <th>{names.title}</th>
            <th>{names.description}</th>
            <th>{names.image}</th>
            <th>{names.link}</th>
            {/* <th>{names.target_websites}</th> */}
            <th>{names.automation_time}</th>
            <th>{names.actions}</th>
        </tr>
    )
}

export default AutomationHeaders;