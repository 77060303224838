import classes from '../../Creatives/Creatives.module.scss';


const WebsiteControlTableItemClicks = (props) => {
    const data = props.data
    const website_url = "https://"+data.domain

    const clicks_data = props.data.clicks_data
    const showByQueryDiv = props.showByQueryDiv


    return (
        <tr>
            {showByQueryDiv?(
                <>
                    <td>{data.w_id}</td>
                    <td><a href={website_url} rel="noreferrer" target='_blank'>{data.domain}</a></td>
                    <td><a href={data.favicon} rel="noreferrer" target='_blank'> <img alt='' className={classes.favicon_icon} src={data.favicon}/></a></td>
                    <td>{clicks_data}</td>
                </>
            ):(
                <>
                    <td>{data.w_id}</td>
                    <td>{data.domain}</td>
                    <td><a href={data.favicon} rel="noreferrer" target='_blank'> <img alt='' className={classes.favicon_icon} src={data.favicon}/></a></td>
                
                    <td>{clicks_data.today}</td>
                    <td>{clicks_data.yesterday}</td>
                    <td>{clicks_data.last_seven_days}</td>
                    <td>{clicks_data.last_thirty_days}</td>
                    <td>{clicks_data.this_month}</td>
                    <td>{clicks_data.last_month}</td>
                    <td>{clicks_data.total_clicks}</td>
                    <td>{clicks_data.updated}</td>
                </>
            )}
        </tr>
    )
}
    
export default WebsiteControlTableItemClicks