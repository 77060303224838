



const EditImageHeaders = (props) => {
    const names = props.headernames

    return(
        <tr>
            <th>{names.title}</th>
            <th>{names.description}</th>
            <th>{names.link}</th>

            <th>{names.file_name}</th>
            <th>{names.image}</th>
            <th>{names.date}</th>
            <th>{names.action}</th>
        </tr>
    )
}


export default EditImageHeaders;