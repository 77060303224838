import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classes from '../Creatives/Creatives.module.scss';

import AutomationHeaders from './AutomationHeaders';
import AutomationTableItem from './AutomationTableItem';
import FunnelTierHeaders from './FunnelTierHeaders';
import FunnelTierTableItem from './FunnelTierTableItem';
import AmountSelect from '../AmountSelect/AmountSelect';
import CustomButton from '../Customs/CustomButton/CustomButton';
import ResponsiveTable from '../Customs/ResponsiveTable/ResponsiveTable';
import DeleteConfirmation from '../Cards/DeleteConfirmation/DeleteConfirmation'
import Pagination from '../Pagination/Pagination';
import CheckAnimation from '../CheckAnimation/CheckAnimation';
import FailAnimation from '../FailAnimation/FailAnimation'
import AddEditFunnelTier from './AddEditFunnelTier'
import EditAutomation from './EditAutomation'

import {TimeOutHandler} from '../../utils/helper_functions';


const Automation = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const api_token = useSelector(state => state.user.token)

    // required variables and names
    const names = useSelector(state => state.ui.language.values.automationContent)
    const automation_table_headers = names.table_headers
    const tier_table_headers = names.tier_table_headers

    // loading animation section
    const [loadingResults, setLoadingResults] = useState(false)

    // table div section
    const [showAutomationDiv, setShowAutomationDiv] = useState(false)

    // pagination section
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfResults, setNumberOfResults] = useState(24)
    const [numberOfResultsAfterQuery, setNumberOfResultsAfterQuery] = useState(0)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [numberOfTotalEntries, setNumberOfTotalEntries] = useState(0)

    // unspecified pagination section
    const [pageNumberUs, setPageNumberUs] = useState(1)
    const [numberOfResultsUs, setNumberOfResultsUs] = useState(24)
    const [numberOfResultsAfterQueryUs, setNumberOfResultsAfterQueryUs] = useState(0)
    const [numberOfPagesUs, setNumberOfPagesUs] = useState(1)
    const [numberOfTotalEntriesUs, setNumberOfTotalEntriesUs] = useState(0)

    // data store section
    const [Data, setData] = useState([])  // automation data
    const [funnelTierData, setFunnelTierData] = useState([])
    const [unspecifiedData, setUnspecifiedData] = useState([])

    // delete from automation states
    const [showDeleteCreativeConfirmation, setShowDeleteCreativeConfirmation] = useState(false)
    const [targetIdToDelete, setTargetIdToDelete] = useState(0)
    const [bulkDelete, setBulkDelete] = useState(false)
    const [showCheckAnimation, setShowCheckAnimation] = useState(false)
    const [showFailAnimation, setShowFailAnimation] = useState(false)
    const [showDeleteErrorAlert, setShowDeleteErrorAlert] = useState(false)

    const [deletedInsideAutomation, setDeletedInsideAutomation] = useState(false)


    // edit automation section
    const [targetEditAutomation, setTargetEditAutomation] = useState('')
    const [showEditAutomation, setShowEditAutomation] = useState(false)

    // select one or multiple or all
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [checkedNumber, setCheckedNumber] = useState(0)

    // funnel tier section 
    const [showFunnelTier, setShowFunnelTier] = useState(true)    
    const [showAddEditFunnelTier, setShowAddEditFunnelTier] = useState(false) 
    const [editFunnelTier, setEditFunnelTier] = useState(false)
    const [addEditOpCompleted, setAddEditOpCompleted] = useState(false) 


    const [targetFunnelTier, setTargetFunnelTier] = useState([])
    const [targetFunnelTierId, setTargetFunnelTierId] = useState('')
    const [targetFunnelTierName, setTargetFunnelTierName] = useState('')
    // delete funnel tier
    const [showDeleteFunnelTierConfirmation, setShowDeleteFunnelTierConfirmation] = useState(false)
    const [deleteFunnelTierMsg, setDeleteFunnelTierMsg] = useState('')
    
    // unspecified automation section
    const [showUnspecifiedDiv, setShowUnspecifiedDiv] = useState(false)

    // add or edit funnel tier 
    const [optionWebsites, setOptionWebsites] = useState([])

    

    useEffect(async()=>{    
        setLoadingResults(true)
        await getFunnelTier()
        setLoadingResults(false)
    }, [])


    // handle chekc and select one /all section
    useEffect(()=>{
        setCheckedNumber(isCheck.length)
    },[isCheck])



    const getFunnelTier = async () => {
        const response = await fetch(`${API_URL}/api/v1/getfunneltier`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: pageNumber,
                items: numberOfResults,
            })
        })
        
        const res_status = response.status
        const data = await response.json()
        // // console.log("get funnel tier is ===>", res_status, data.entries, data.total_entires, data.num_unspecified, data.total_unspecified)
       
        if(res_status === 200) {
            const total_entires = data.total_entires
            setNumberOfTotalEntries(total_entires)
            setFunnelTierData(data.res)   
            setOptionWebsites(data.option_websites)
            setNumberOfResultsAfterQuery(data.entries)

            // unspecified
            setUnspecifiedData(data.unspecified)
            setNumberOfTotalEntriesUs(data.total_unspecified)
            setNumberOfResultsAfterQueryUs(data.num_unspecified)
            
            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }            
        }
    }


    const getAutomateCreative = async (targettier) => { 
        // // console.log("\n\nTarget funnel tier is in get funneltire:", targetFunnelTierId)
 
        const response = await fetch(`${API_URL}/api/v1/getautomatedcreatives`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                page: pageNumber,
                items: numberOfResults,
                tier_id: targettier,
            })
        })
        
        const res_status = response.status
        const data = await response.json()
        // // console.log("getautomatedcreatives is ===>", res_status, data.res, data.entries, data.total_entires, typeof data.total_entires, numberOfResults)
       
        if(res_status === 200) {
            const total_entires = data.total_entires
            setNumberOfTotalEntries(total_entires)

            if(!showUnspecifiedDiv){
                setData(data.res)   
            }else{
                setUnspecifiedData(data.res)   
            }

            setNumberOfResultsAfterQuery(data.entries)
            
            if(numberOfResults > total_entires){
                setNumberOfPages(1)
            }else{
                setNumberOfPages(Math.ceil(total_entires/numberOfResults))
            }
            // // console.log("getautomatedcreatives again is ===>", total_entires, data.entries, numberOfResults, numberOfResults > total_entires, Math.ceil(total_entires/numberOfResults))
            
            if(total_entires === 0){
                if(!showUnspecifiedDiv){
                    setShowAutomationDiv(false)
                }else{
                    setShowUnspecifiedDiv(false)
                }
            }
        }
    }

    const handleChangeAutomationTiming = (e) => {
        if(!showUnspecifiedDiv){
            setTargetEditAutomation(Data[e.target.id])
        }else{
            setTargetEditAutomation(unspecifiedData[e.target.id])
        }
        setShowEditAutomation(true)
    }





    /* ************************* automation delete section **************************/
    const singleDelete = (e) => {
        setBulkDelete(false)
        setShowDeleteCreativeConfirmation(true)
        setTargetIdToDelete(parseInt(e.target.id))
    }

    const multipleDelete = () =>{
        // // console.log("Multiple delete clicked :", showFunnelTier, showUnspecifiedDiv, showAutomationDiv)
       
        setBulkDelete(true)
        if(showFunnelTier){
            if(showUnspecifiedDiv){
                setShowDeleteCreativeConfirmation(true)

            }else{
                setDeleteFunnelTierMsg(names.delete_multiple_funnel_tier)
                setShowDeleteFunnelTierConfirmation(true)
            }
        }else{
            setShowDeleteCreativeConfirmation(true)
        }
        // console.log("Indexes to be removed at bulk is :", isCheck, checkedNumber, bulkDelete)
    }


    const goBackToInitialDeleteState = async () =>{        
        setTargetIdToDelete('')   
        setBulkDelete(false)
        goBackToClickInitialState()

        setLoadingResults(true)
        if(showFunnelTier){
            await getFunnelTier()
        }else{
            if(!showUnspecifiedDiv){
                await getAutomateCreative(targetFunnelTierId)
            }else{
                await getAutomateCreative('')
            }
        }
        setLoadingResults(false)
    }

    // // delete operation section for automation creative
    const deleteTargetAutomation = async () => {
        setShowDeleteCreativeConfirmation(false)
        setLoadingResults(true)
        
        var deleteTargetArticlesData = []
        var target_creative_to_delete;
        
        var tier_id;
        if(!showUnspecifiedDiv){
            tier_id = targetFunnelTierId
            target_creative_to_delete = Data[targetIdToDelete]

        }else{
            tier_id = ''
            target_creative_to_delete = unspecifiedData[targetIdToDelete]
        }

        if(bulkDelete){
            // // console.log("Yes bulk delete automation/us req >>>>: ", bulkDelete, isCheck, isCheck.length) 
            
            for(let i=0;  i<isCheck.length; i++){
                var each_creative;

                if(!showUnspecifiedDiv){
                    each_creative = Data[isCheck[i]]
                }else{
                    each_creative = unspecifiedData[isCheck[i]]
                }
                var eachCreativeToDelete = {
                    id:each_creative['id'],
                    website:each_creative['website'],
                }
                deleteTargetArticlesData.push(eachCreativeToDelete)
            }

        }else{
            // console.log("remove single automation creative :", targetIdToDelete, target_creative_to_delete, typeof target_creative_to_delete)

            var eachCreativeToDelete = {
                id:target_creative_to_delete['id'],
                website:target_creative_to_delete['website'],
            }
            deleteTargetArticlesData.push(eachCreativeToDelete)
        }

        // // console.log('=============>> tier id in delete funnel creative is :', tier_id)
        const response = await fetch(`${API_URL}/api/v1/updateautomatedcreatives`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                tier_id:tier_id,
                target_creatives : deleteTargetArticlesData,
            })
        })
        
        const res_status = response.status
        const data = await response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            TimeOutHandler(()=>{
                setShowCheckAnimation(false)
            }, 1500)
            setDeletedInsideAutomation(true)
        }
        else{
            console.log("=== Error in delete articles ===> ", data.success, res_status, data.error)
            setShowDeleteErrorAlert(true)
            setShowFailAnimation(true)
            
            TimeOutHandler(()=>{
                setShowFailAnimation(false)
            }, 1500)
            TimeOutHandler(()=>{
                setShowDeleteErrorAlert(false)
            }, 4000)
        }
        goBackToInitialDeleteState()
        setLoadingResults(false)
    }



    // delete funnel tier
    const deleteTargetFunnelTier = async () => {
        setShowDeleteFunnelTierConfirmation(false)
        setLoadingResults(true)
        
        var deleteTargetFunnelTierData = []
        if(bulkDelete){
            // bulk delete
            for(let i=0;  i<isCheck.length; i++){
                deleteTargetFunnelTierData.push(funnelTierData[isCheck[i]].id)
            }
        }else{
            // single delete
            deleteTargetFunnelTierData.push(targetFunnelTierId)
        }

        // // console.log('=============>> delete funnel tier data is :', deleteTargetFunnelTierData)
        const response = await fetch(`${API_URL}/api/v1/funneltier`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${api_token}`},
            body: JSON.stringify({
                tier_ids : deleteTargetFunnelTierData,
            })
        })
        
        const res_status = response.status
        const data = await response.json()

        if(res_status === 200) {
            setShowCheckAnimation(true)
            console.log("automation remove response is is ===>", data.success, res_status, data.msg)
            TimeOutHandler(()=>{
                setShowCheckAnimation(false)
            }, 1500)
        }
        else{
            console.log("=== Error in delete articles ===> ", data.success, res_status, data.error)
            setShowDeleteErrorAlert(true)
            setShowFailAnimation(true)
            TimeOutHandler(()=>{
                setShowFailAnimation(false)
            }, 1500)

            TimeOutHandler(()=>{
                setShowDeleteErrorAlert(false)
            }, 4000)

        }
        goBackToInitialDeleteState()
    }


    const handleDeleteFunnelTier = (e) =>{
        // // console.log("handleDeleteFunnelTier clicked :", e.target.id)
        
        const target_obj = funnelTierData[e.target.id]
        setTargetFunnelTierId(target_obj.id)
        setDeleteFunnelTierMsg(`${names.delete_title_funnel_tier}"${target_obj.tier_name}"?`)
        setShowDeleteFunnelTierConfirmation(true)
    }



    useEffect(()=>{
        if(isCheck.length === 0){
            // // console.log("ischeck length zero:", isCheck.length)
            setIsCheckAll(false)
        }
    }, [isCheck])

    const closeDeleteConfirmationHandler = () => {
        setShowDeleteCreativeConfirmation(false)
        setShowDeleteFunnelTierConfirmation(false)
    }


    const handleSelectAll = e => {        
        // // console.log("Hanlde selectall clicked :", e.target, isCheckAll) 

        setIsCheckAll(!isCheckAll);
        if(showFunnelTier){
            if(showUnspecifiedDiv){
                setIsCheck(unspecifiedData.map((item, index) => index));
            }else{
                setIsCheck(funnelTierData.map((item, index) => index));
            }
        }else{
            setIsCheck(Data.map((item, index) => index));
        }
        if (isCheckAll) {setIsCheck([]);}

    };


    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, parseInt(id)]);

        if(!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        }
        // // console.log("checklist is :", isCheck, checked, isCheckAll)
    };


    
    const goBackToClickInitialState = () =>{
        setIsCheckAll(false)
        setIsCheck([])
        setCheckedNumber(0)
    }

    const toggleDisplayUnspecifiedDiv = () =>{
        setShowUnspecifiedDiv(!showUnspecifiedDiv)   
        goBackToClickInitialState()
    }

    
    const handleHidAutomationDiv = async () =>{
        setShowFunnelTier(!showFunnelTier)
        setShowAutomationDiv(false)
        goBackToClickInitialState()
        
        if(deletedInsideAutomation){
            setLoadingResults(true)
            await getFunnelTier()
            setLoadingResults(false)
            setDeletedInsideAutomation(false)
        }
    }

    const showAutomationDivForTargetTier = async (e) =>{
        goBackToClickInitialState()

        const target_obj = funnelTierData[e.target.id]
        setTargetFunnelTierId(target_obj.id)
        setTargetFunnelTierName(`: ${target_obj['tier_name']}`)
        setShowFunnelTier(!showFunnelTier)
        setLoadingResults(true)

        setShowAutomationDiv(true)
        await getAutomateCreative(target_obj['id'])
        setLoadingResults(false)
    }


    useEffect(()=>{
        const getFunnelTierAgain = async() =>{
            if(addEditOpCompleted){
                setLoadingResults(true)
                await getFunnelTier()
                setLoadingResults(false)
                setAddEditOpCompleted(false)
            }
        }
        getFunnelTierAgain()
    }, [addEditOpCompleted])


    const toggleAddEditFunnelTier = async () =>{
        setShowAddEditFunnelTier(!showAddEditFunnelTier)
    }
    
    const handleSetEditFunnelTier = (e) =>{
        setEditFunnelTier(true)
        setShowAddEditFunnelTier(true)
        setTargetFunnelTier(funnelTierData[e.target.id])
    }

    const handleSetAddFunnelTier = () =>{
        setEditFunnelTier(false)
        setShowAddEditFunnelTier(true)
    }

    const AddEditFunnelTierProps={
        editFunnelTier,
        toggleAddEditFunnelTier,
        targetFunnelTier,
        optionWebsites,

        setOptionWebsites,
        setShowAddEditFunnelTier,
        setEditFunnelTier,
        setAddEditOpCompleted,
    }



    return (
        <>
        {showAddEditFunnelTier && <AddEditFunnelTier {...AddEditFunnelTierProps}/>}

        {showEditAutomation && <EditAutomation 
                                setShowEditAutomation={setShowEditAutomation} 
                                setLoadingResults={setLoadingResults} 
                                targetEditAutomation={targetEditAutomation} 
                                setTargetEditAutomation={setTargetEditAutomation} 
                                getAutomateCreative={getAutomateCreative}
                                showUnspecifiedDiv={showUnspecifiedDiv}
                                targetFunnelTierId={targetFunnelTierId}
                                />}

        {showDeleteCreativeConfirmation && <DeleteConfirmation delete_title={showAutomationDiv? names.delete_from_tier_title: names.delete_title} 
                                                onYes={deleteTargetAutomation} 
                                                onNo={closeDeleteConfirmationHandler}/>}
        {showDeleteFunnelTierConfirmation && <DeleteConfirmation delete_title={deleteFunnelTierMsg} 
                                                onYes={deleteTargetFunnelTier} 
                                                onNo={closeDeleteConfirmationHandler}/>}
        
        
        {showCheckAnimation && <CheckAnimation/>}
        {showFailAnimation && <FailAnimation/>}
    


        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.main_top_extra_padding}>
                    {showFunnelTier?(
                        showUnspecifiedDiv?(<div className={classes.main_title}>{names.unspecified}</div>):(<div className={classes.main_title}>{names.funnel_tier}</div>)
                    ):(
                        <div className={classes.main_title}>{names.automation} {targetFunnelTierName}</div>
                    )}

                    <div className={classes.main_operation}>
                        {showFunnelTier?(
                            showUnspecifiedDiv?(
                                <div className={classes.customButton}><CustomButton reverse color='orange' name={names.showFunnelTier} onClick={toggleDisplayUnspecifiedDiv}/></div>
                                ):(
                                    <>
                                        <div className={classes.customButton}><CustomButton color='blue' name={names.showUnspecifiedDiv} onClick={toggleDisplayUnspecifiedDiv}/></div>
                                        <div className={classes.customButton}><CustomButton color='green' name={names.addFunnelTier} onClick={handleSetAddFunnelTier}/></div>
                                    </>
                                    )
                            ):(
                                <div className={classes.customButton}><CustomButton reverse color='orange' name={names.showFunnelTier} onClick={handleHidAutomationDiv}/></div>
                            )}

                        <>
                            {checkedNumber>0 && <div className={classes.customButton}><CustomButton color='red' name={names.delete} onClick={multipleDelete}/></div>}
                        </>
                    </div>
                </div>


                {showFunnelTier?(
                    <>
                        {showUnspecifiedDiv?(
                            <ResponsiveTable loadingResults={loadingResults}>
                                <>  
                                    <thead> <AutomationHeaders isCheckAll={isCheckAll} headernames={automation_table_headers} handleSelectAll={handleSelectAll}/></thead>
                                    <tbody className={classes.table_data}>
                                        {(unspecifiedData.map((item, index) => <AutomationTableItem 
                                                                                data={item} 
                                                                                index={index} 
                                                                                isCheckList={isCheck} 
                                                                                checkedNumber={checkedNumber} 
                                                                                handleClick={handleClick} 
                                                                                handleChangeAutomationTiming={handleChangeAutomationTiming} 
                                                                                handleDeleteFromAutomation={singleDelete} />))}

                                        {unspecifiedData.length === 0 && <div className={classes.no_data_found_div}>{names.no_unspecified_creative_found}</div>} 
                                    </tbody>
                                </>
                            </ResponsiveTable>
                        ):(
                            <ResponsiveTable loadingResults={loadingResults}>
                                <>
                                    <thead> <FunnelTierHeaders isCheckAll={isCheckAll} headernames={tier_table_headers} handleSelectAll={handleSelectAll}/></thead>
                                    <tbody className={classes.table_data}>
                                        {(funnelTierData.map((item, index)=><FunnelTierTableItem 
                                                                                data={item} 
                                                                                index={index} 
                                                                                isCheckList={isCheck} 
                                                                                checkedNumber={checkedNumber} 
                                                                                showAutomationDivForTargetTier={showAutomationDivForTargetTier} 
                                                                                handleDeleteFunnelTier={handleDeleteFunnelTier}
                                                                                handleSetEditFunnelTier={handleSetEditFunnelTier}
                                                                                handleClick={handleClick}
                                                                                />))}

                                        {funnelTierData.length === 0 && <div className={classes.no_data_found_div}>{names.no_funnel_tier_found}</div>} 
                                    </tbody>
                                </>
                            </ResponsiveTable>
                        )}
                    </>
                    ):(

                    // automation creative section
                    <ResponsiveTable loadingResults={loadingResults}>
                        {showAutomationDiv?(
                            <>
                                <thead> <AutomationHeaders isCheckAll={isCheckAll} headernames={automation_table_headers} handleSelectAll={handleSelectAll}/></thead>
                                <tbody className={classes.table_data}>
                                    {(Data.map((item, index) =>  <AutomationTableItem 
                                                                    data={item} 
                                                                    index={index} 
                                                                    isCheckList={isCheck} 
                                                                    checkedNumber={checkedNumber} 
                                                                    handleClick={handleClick} 
                                                                    handleChangeAutomationTiming={handleChangeAutomationTiming} 
                                                                    handleDeleteFromAutomation={singleDelete} />))}
                                    {Data.length === 0 && <div className={classes.no_data_found_div}>{names.no_data_found}</div>} 
                                </tbody>
                            </>
                        ):(
                            <>
                                <thead> <AutomationHeaders isCheckAll={isCheckAll} headernames={automation_table_headers} handleSelectAll={handleSelectAll}/></thead>
                                <tbody className={classes.table_data}>
                                    {(Data.map((item, index) =>  <AutomationTableItem data={item} index={index} isCheckList={isCheck}/>))}
                                    <div className={classes.no_data_found_div}>{names.no_data_found}</div>
                                </tbody>
                            </>
                        )}
                    </ResponsiveTable>
                )}


                <div className={classes.main_bottom}>
                    <AmountSelect pageNumber={pageNumber} numberOfResultsAfterQuery={numberOfResultsAfterQuery} numberOfResults={numberOfResults} numberOfPages={numberOfPages} totalEntries={numberOfTotalEntries} onChange={(e) => setNumberOfResults(e.target.value)}/>

                    {showDeleteErrorAlert && <div className={classes.formAlertDiv}>Error occurred in delete operation</div>}

                    {numberOfPages >= 1 && <Pagination pageNumber={pageNumber} setPageNumber={setPageNumber} numberOfPages={numberOfPages}/>}
                </div>
            </div>
        </div>
    </>
    )
}

export default Automation;