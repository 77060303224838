
const HeadersAllWebsitesClicks = (props) => {
    let names = props.headernames
    const showByQueryDiv = props.showByQueryDiv

    return (
        <tr>
            {showByQueryDiv?(
                <>
                    <th>{names.w_id}</th>
                    <th>{names.domain}</th>
                    <th>{names.favicon}</th>
                    <th>{names.total_clicks}</th>
                </>
            ):(
                <>
                    <th>{names.w_id}</th>
                    <th>{names.domain}</th>
                    <th>{names.favicon}</th>

                    <th>{names.today}</th>
                    <th>{names.yesterday}</th>
                    <th>{names.last_seven_days}</th>
                    <th>{names.last_thirty_days}</th>
                    <th>{names.this_month}</th>
                    <th>{names.last_month}</th>
                    <th>{names.total_clicks}</th>
                    <th>{names.updated}</th>
                </>
            )}
        </tr>
    )
}
export default HeadersAllWebsitesClicks