// import classes from '../Creatives/CreativesUser/CreativesTableItem/CreativesTableItem.module.scss'

const FunnelTierHeaders = (props) => {
    const names = props.headernames
    
    return (
        <tr>
            <th><input id='selectAll' type="checkbox" name='selectAll' checked={props.isCheckAll} onChange={props.handleSelectAll}/><p>Select</p></th>
            <th>{names.tier_name}</th>
            <th>{names.target_websites}</th>
            <th>{names.target_websites_len}</th>
            <th>{names.target_automation_len}</th>
            <th>{names.created_at}</th>
            <th>{names.updated_at}</th>
            <th>{names.actions}</th>
        </tr>
    )
}

export default FunnelTierHeaders;


