import classes from './NavBarItem.module.scss'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { uiActions } from '../../store/ui-slice'


const NavBarItem = (props) => {
    const param = useParams()
    const dispatch = useDispatch()
    let isActive = param.page === props.route

    const minimizeNavBar =  useSelector(state => state.ui.minimizeNavBar)

    const name = props.name ? props.name : 'Please Add Name'
    const image_src = props.image_src
    const image_alt = props.image_alt ? props.image_alt : 'Please Add Alt'

    const route = `/${props.route}`

    // const closeMobileNavBar = () => {
    //     dispatch(uiActions.setShowNavBarMobile(false))
    // }


    return (
        <NavLink to={route} className={!isActive ? classes.container : classes.selected}> 
        {/* onClick={closeMobileNavBar}> */}
            <div className={classes.image}><img src={image_src} alt={image_alt}/></div>
            {!minimizeNavBar && <div className={classes.name}>{name}</div>}
        </NavLink>
    )
}

export default NavBarItem